import { AuthApi, FlowValidation, FlowsApi, NodeType } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";
import { inlineRefs } from "src/lib/utils";
const FLOWS_API = new FlowsApi(getAxiosParams(), undefined, axios);
const AUTH_API = new AuthApi(getAxiosParams(), undefined, axios);

export const startFlow = async (flowId: string) => {
  return FLOWS_API.startFlowApiV1FlowsFlowIdStartPost(flowId).then(
    (response) => {
      return response.data;
    }
  );
};

export const stopFlow = async (flowId: string) => {
  return FLOWS_API.stopFlowApiV1FlowsFlowIdStopDelete(flowId).then(
    (response) => {
      return response.data;
    }
  );
};

export const getIssues = async (flowId: string): Promise<FlowValidation> => {
  return FLOWS_API.validateFlowApiV1FlowsFlowIdValidatePost(flowId).then(
    (response) => {
      return response.data;
    }
  );
};

export const readNodeTypes = async (): Promise<NodeType[]> =>
  FLOWS_API.readNodeTypesApiV1FlowsNodesTypesGet().then((response) => {
    response.data.forEach((nodeType) => {
      nodeType.init = inlineRefs(nodeType.init);
      if (
        ["text", "formattext", "number", "boolean", "integer"].includes(
          nodeType.kls.toLowerCase()
        )
      ) {
        if (nodeType.init.properties?.name) {
          nodeType.init.properties.name.format = "hidden";
          nodeType.init.properties.name.description = "";
        }
      }
      nodeType.inputs = inlineRefs(nodeType.inputs);
      nodeType.outputs = inlineRefs(nodeType.outputs);
    });

    return response.data;
  });

export const getOauthUrl = async (
  nodeKlasses: string[],
  nodeTypes: NodeType[] | null
): Promise<string | null> => {
  if (!nodeTypes) {
    return null;
  }

  // iterate over nodes. if any has .scopes, check that the user has them
  // if not, open the modal
  const nodeTypesWithScopes = nodeTypes
    .filter((nt) => nt.scopes && nt.scopes.length > 0)
    .sort((a, b) => b.scopes.length - a.scopes.length); // sort by most scopes first

  for (const kls of nodeKlasses) {
    const nodeType = nodeTypesWithScopes.find((nt) => nt.kls === kls);
    if (nodeType) {
      const scopesStr = nodeType.scopes.join(" ");
      // store scopes in local storage
      localStorage.setItem("scopes", scopesStr);

      const redirectUrl = window.location.origin + "/auth/scopes";
      const scopeUrl = await AUTH_API.loginAuthApiV1AuthLoginOauthGet(
        redirectUrl,
        scopesStr
      ).then((response) => {
        if (response.data?.url) {
          return response.data.url;
        }
      });
      if (scopeUrl) {
        return scopeUrl;
      }
    }
  }
  return null;
};
