"use client";

import { ReactNode } from "react";
import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

const Features = () => {
  const bgColor = useColorModeValue("brand.800", "brand.100");
  return (
    <Box bg={bgColor} position={"relative"}>
      <Flex
        flex={1}
        zIndex={0}
        display={{ base: "none", lg: "flex" }}
        position={"absolute"}
        width={"100%"}
        insetY={0}
        right={0}
      >
        <Flex
          bgGradient={"linear(to-r, gray.800 10%, transparent)"}
          w={"full"}
          h={"full"}
        />
      </Flex>
      <Container maxW={"100%"} zIndex={10} position={"relative"} px="16">
        <Stack direction={{ base: "column", lg: "row" }}>
          <Stack
            color={"gray.400"}
            justify={{ lg: "center" }}
            py={{ base: 4, md: 20, xl: 60 }}
          >
            <Box mb={{ base: 8, md: 20 }}>
              <Text
                fontFamily={"heading"}
                fontWeight={700}
                textTransform={"uppercase"}
                mb={3}
                fontSize={"xl"}
                color={"gray.500"}
              >
                Your imagination is the limit
              </Text>
              <Heading
                color={"white"}
                mb={5}
                fontSize={{ base: "3xl", md: "5xl" }}
              >
                Build complex AI flows simply
              </Heading>
              <Text fontSize={"xl"} color={"gray.400"}></Text>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} >
              {stats.map((stat) => (
                <Box key={stat.title}>
                  <Text
                    fontFamily={"heading"}
                    fontSize={"3xl"}
                    color={"white"}
                    mb={3}
                  >
                    {stat.title}
                  </Text>
                  <Text fontSize={"xl"} color={"gray.400"}>
                    {stat.content}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </Stack>
          <Flex flex={1} />
        </Stack>
      </Container>
    </Box>
  );
};

const StatsText = ({ children }: { children: ReactNode }) => (
  <Text as={"span"} fontWeight={700} color={"white"}>
    {children}
  </Text>
);

const stats = [
  {
    content: (
      <>
        <StatsText>100+ blocks</StatsText> ready to use: AI, Email, Excel, Voice
        Call, Image Generation, and more
      </>
    ),
  },
  {
    content: (
      <>
        <StatsText>Deploy</StatsText> in one click to the cloud or on-premises
      </>
    ),
  },
  {
    content: (
      <>
        <StatsText>Monitor</StatsText> your flows with real-time logs and
        metrics
      </>
    ),
  },
  {
    content: (
      <>
        <StatsText>Customize</StatsText> blocks with your own code (Python) or
        with our help
      </>
    ),
  },
];

export default Features;
