import {
  Box,
  Button,
  useDisclosure,
  Text,
  VStack,
  Heading,
  StackDivider,
  Collapse,
  Flex,
} from "@chakra-ui/react";
import { getUserFriendlyName } from "src/nodesFriendly";
import React from "react";
import { TypeDesc } from "src/components/node";
const typeMapping = {
  string: "Text",
  boolean: "True/False",
  array: "List",
  object: "Object",
};

export const getFriendlyTitle = (paramData) => {
  if (!paramData) {
    return "unknown";
  }
  return paramData.title || paramData.allOf?.[0].title || "unknown";
};

export const getFriendlyType = (paramData) => {
  let typeStr = "";
  if (paramData.type === "object") {
    typeStr = paramData.title || "Object";
  } else if (paramData.type) {
    if (paramData.format === "date-time") {
      typeStr = "Datetime";
    } else {
      typeStr = typeMapping[paramData.type] || paramData.type;
    }
  } else {
    typeStr = (paramData.allOf || paramData.anyOf || paramData.oneOf)
      ?.map((v) => {
        if (v.type === "object") {
          return typeMapping[v.title] || v.title;
        } else {
          return typeMapping[v.type] || v.type;
        }
      })
      .join(" | ");
  }
  return (typeStr || "").toLocaleUpperCase();
};

const ParameterCard = ({ paramData, isRequired }) => {
  return (
    <Flex align="stretch" direction="column">
      <Flex justify="space-between" align="center" mb={2}>
        <Box>
          <Heading as="h3" size="sm">
            {paramData.title}
            {isRequired && (
              <Text as="span" color="red.500" ml={1}>
                *
              </Text>
            )}
          </Heading>
          {paramData.default && (
            <Text fontSize="xs" fontStyle="italic">
              Default: {paramData.default}
            </Text>
          )}
        </Box>
        <Text fontWeight="bold">{getFriendlyType(paramData)}</Text>
      </Flex>
      {paramData.description && (
        <Text>Description: {paramData.description}</Text>
      )}

      {paramData.examples?.length > 0 && (
        <Box mt={2} px={2}>
          <Text>Example:</Text>
          {paramData.examples?.map((example) => (
            <Box
              px={2}
              bg="gray.50"
              borderWidth={1}
              borderRadius="md"
              key={example}
            >
              <Text>{JSON.stringify(example, null, 2)}</Text>
            </Box>
          ))}
        </Box>
      )}
    </Flex>
  );
};

const getSchemaFormatted = (schema, type) => {
  const { properties, required, description } = schema;
  const fontSize = "sm";
  if (!properties || Object.entries(properties).length == 0) {
    return (
      <Box ps={4} fontSize={fontSize} p={2} minW="100%">
        {description && <Text>{description}</Text>}
        <Text>{type === "output" ? "No outputs" : "No Inputs"}</Text>
      </Box>
    );
  }
  return (
    <Box minW="100%">
      {description && <Text mb={2}>{description}</Text>}
      <Box
        minW="100%"
        ps={4}
        fontSize={fontSize}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        p={2}
      >
        {/* <Heading as="h2" size="lg" mb={4}>
          {title} Parameters
        </Heading> */}
        <VStack
          spacing={4}
          align="stretch"
          divider={<StackDivider />}
          minW="100%"
        >
          {Object.entries(properties)
            .filter(([k, v]) => {
              if (type === "init") {
                return k !== "pos_x" && k !== "pos_y";
              } else {
                return true;
              }
            })
            .filter(([k, v]) => {
              if (v.format === "hidden") {
                return false;
              }
              return true;
            })
            .map(([key, value]) => (
              <ParameterCard
                key={key}
                paramData={value}
                isRequired={required?.includes(key)}
              />
            ))}
        </VStack>
      </Box>
    </Box>
  );
};

const NodeSummary = ({ nodeType, outputSchema }) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <VStack alignItems="start" align="stretch" spacing={4} minW="100%">
      <VStack alignItems="start" align="stretch" minW="100%">
        <Heading size="lg">{getUserFriendlyName(nodeType.kls)}</Heading>
        <TypeDesc desc={nodeType.desc} />
      </VStack>
      <VStack alignItems="start" align="stretch" minW="100%">
        <Heading size="md">Inputs</Heading>
        {getSchemaFormatted(nodeType.inputs, "target")}
      </VStack>
      <VStack alignItems="start" align="stretch" minW="100%">
        <Heading size="md">Outputs</Heading>
        {getSchemaFormatted(outputSchema, "source")}
      </VStack>
      <VStack alignItems="start" align="stretch" minW="100%">
        <Flex justifyContent="end" minW="100%">
          <Button size="xs" variant="ghost" onClick={onToggle}>
            Show static input
          </Button>
        </Flex>
        <Box as={Collapse} in={isOpen} minW="100%">
          <VStack alignItems="start" align="stretch" minW="100%">
            <Heading size="md">Static inputs</Heading>
            <Text fontSize="sm" color="gray.500" fontStyle="italic">
              These inputs do not change during execution.
            </Text>
            {getSchemaFormatted(nodeType.init, "init")}
          </VStack>
        </Box>
      </VStack>
    </VStack>
  );
};

export default NodeSummary;
