import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios, { getAxiosParams } from "./lib/axios.config";
import { TemplateRead, TemplatesApi } from "typescript-axios";
import {
  Box,
  Button,
  HStack,
  Heading,
  Text,
  useDisclosure,
  Spinner,
  StackDivider,
  VStack,
  useToast,
} from "@chakra-ui/react";
import SubscribeModal, { canCreateNewFlow } from "./studio/SubscribeModal";

import {
  ReactFlow,
  Controls,
  Background,
  Panel,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
} from "@xyflow/react";
import { MdRocket, MdShare } from "react-icons/md";
import { readNodeTypes } from "./flowActions";
import { createCustomNodes, getEdgesAndNodesFromJson } from "./appUtils";
import Modal from "./components/Modal";
import JSONForm, { toastPromise } from "./JSONForm";
import { RJSFSchema } from "@rjsf/utils";
const TEMPLATES_API = new TemplatesApi(getAxiosParams(), undefined, axios);

const ExploreEdit = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const [template, setTemplate] = useState<TemplateRead | null>(null);
  const [isLoading] = useState<boolean>(false);
  const [createForm, setCreateForm] = useState<RJSFSchema | null>(null);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [customNodes, setCustomNodes] = useState({});
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSubscribeOpen,
    onOpen: onSubscribeOpen,
    onClose: onSubscribeClose,
  } = useDisclosure();

  const createFlowFromTemplate = React.useCallback(
    (templateId: string, body: object) => {
      return TEMPLATES_API.createFlowFromTemplateApiV1TemplatesTemplateIdFlowsPost(
        templateId,
        body
      ).then((r) => {
        navigate(`/studio/${r.data.id}`);
      });
    },
    [navigate, templateId]
  );

  const createFromTemplate = React.useCallback(() => {
    if (templateId) {
      toast({
        status: "info",
      })
      TEMPLATES_API.getTemplateFormApiV1TemplatesTemplateIdFormGet(
        templateId
      ).then((r) => {
        toast.closeAll();
        if (r.data === null) {
          createFlowFromTemplate(templateId, {});
        } else {
          setCreateForm(r.data);
        }
        onOpen();
      });
    }
  }, [templateId]);

  React.useEffect(() => {
    readNodeTypes().then((schema) => {
      setCustomNodes(createCustomNodes(schema, null, null, null, true));
    });
  }, []);

  const onBuildClick = React.useCallback(() => {
    canCreateNewFlow().then((canCreate) => {
      if (canCreate) {
        createFromTemplate();
      } else {
        onSubscribeOpen();
      }
    });
  }, []);

  React.useEffect(() => {
    if (templateId) {
      TEMPLATES_API.readTemplateApiV1TemplatesTemplateIdGet(templateId)
        .then((r) => {
          setTemplate(r.data);
          const [edges, nodes] = getEdgesAndNodesFromJson(
            r.data.template_json || {}
          );
          setNodes(nodes);
          setEdges(edges);
        })
        .catch((e) => {
          if (e.response?.status === 404) {
            navigate("/explore");
          } else {
            throw e;
          }
        });
    }
  }, [templateId]);

  if (template === null) {
    return <Spinner />;
  }
  return (
    <Box h="full">
      <SubscribeModal
        isOpen={isSubscribeOpen}
        onOpen={onSubscribeOpen}
        onClose={() => {
          onSubscribeClose();
        }}
        onSuccess={() => {
          onSubscribeClose();
        }}
      />
      <VStack mt="2">
        <Modal isOpen={isOpen} onClose={onClose}>
          {createForm ? (
            <JSONForm
              uiSchema={{
                "ui:options": { label: false },
                pos_x: { "ui:widget": "hidden" },
                pos_y: { "ui:widget": "hidden" },
              }}
              schema={createForm}
              onSubmit={(formData) =>
                createFlowFromTemplate(templateId, formData)
              }
            />
          ) : (
            <Spinner />
          )}
        </Modal>
        <Heading>Template: {template.name}</Heading>
        <Text>{template.desc}</Text>
      </VStack>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={customNodes || {}}
          fitView
        >
          <Controls />
          <Background />
          <Panel position="top-left">
            <HStack
              border="1px solid"
              borderColor="gray.300"
              p={2}
              bg="white"
              divider={<StackDivider />}
            >
              <Button
                id="validate-btn"
                isDisabled={isLoading}
                size="sm"
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  toast({
                    title: "Link copied to clipboard.",
                    description: "Share it with anyone!",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                }}
                leftIcon={<MdShare />}
                colorScheme="blue"
                variant="outline"
              >
                Share
              </Button>
              <Button
                id="test-btn"
                isDisabled={isLoading}
                size="sm"
                leftIcon={<MdRocket />}
                onClick={onBuildClick}
                colorScheme="brand"
              >
                Use this template
              </Button>
            </HStack>
          </Panel>
        </ReactFlow>
      </ReactFlowProvider>
    </Box>
  );
};
export default ExploreEdit;
