import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Text,
  MenuList,
  Spinner,
  Button,
  Flex,
} from "@chakra-ui/react";
import { FiBell, FiCheck, FiDelete } from "react-icons/fi";
import useNotifs from "./useNotifs";
import axios, { getAxiosParams } from "src/lib/axios.config";
import { Notif, NotifsApi } from "typescript-axios";
import dayjs from "src/lib/dayjs.config";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const NOTIFS_API = new NotifsApi(getAxiosParams(), undefined, axios);

const NotifsWidget = () => {
  const { notifs, isLoading, isError, mutate } = useNotifs();
  const navigate = useNavigate();
  const clearNotifs = () => {
    NOTIFS_API.deleteMeApiV1NotifsMeDelete().then(() => mutate([]));
  };

  const clearNotif = (notif: Notif) => {
    NOTIFS_API.deleteNotifApiV1NotifsNotifIdDelete(notif.id).then(() => {
      mutate((notifs || []).filter((n) => n.id !== notif.id));
    });
  };

  return (
    <Menu>
      <Box me="2" position="relative">
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FiBell />}
          variant="outline"
        />
        {
          // Show a badge if there are notifications
          notifs && notifs.length > 0 && (
            <Box
              position="absolute"
              top="-2px"
              right="-2px"
              bg="red.500"
              color="white"
              borderRadius="50%"
              w="20px"
              h="20px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {notifs.length}
            </Box>
          )
        }
      </Box>
      <MenuList>
        {(isLoading || isError) && (
          <MenuItem justifyContent="center">
            <Spinner>Loading...</Spinner>
          </MenuItem>
        )}
        {!isLoading && notifs && notifs.length === 0 && (
          <MenuItem>No notifications</MenuItem>
        )}
        {!isLoading && notifs && notifs.length > 0 && (
          <>
            {notifs.map((notif) => (
              <MenuItem
                key={notif.id}
                onClick={() => {
                  if (notif.type === "insta_dm_escalation") {
                    navigate("/account");
                  }
                }}
              >
                <Flex justifyContent="space-between">
                  <Box me="4">
                    <Text>{notif.desc}</Text>
                    <Text fontSize="xs">
                      {dayjs.utc(notif.timestamp).local().format("LLL")}
                    </Text>
                  </Box>
                  <IconButton
                    variant="ghost"
                    colorScheme="red"
                    aria-label="Clear notification"
                    icon={<MdDelete />}
                    onClick={() => clearNotif(notif)}
                  />
                </Flex>
              </MenuItem>
            ))}
            <MenuItem as={Button} size="xs" onClick={clearNotifs}>
              Clear notifications
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export default NotifsWidget;
