import {
  Container,
  Stack,
  Box,
  Heading,
  Text,
  Button,
  useColorModeValue,
  Link,
  VStack,
} from "@chakra-ui/react";

import { ReactFlowProvider } from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import Features from "src/home/features";
import UseCases from "src/home/use-cases";
import Pricing from "src/home/pricing";
import Contact from "src/home/contact";
import Demo from "src/components/demo";
import { MdRocket } from "react-icons/md";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const requestDemoHref = "https://calendly.com/alexvillard/15min";
const Hero = () => {
  const brandColor = useColorModeValue("brand.100", "brand.100");
  return (
    <Container maxW={"7xl"} ps={{ base: 2, md: 16 }} mb={12}>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        // py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={2} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
          >
            <Text
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                left: 0,
                bg: "primary",
                zIndex: -1,
              }}
            >
              Build any AI flow visually.
            </Text>
            <br />
            <Text as={"span"} bg={brandColor}>
              No code needed.
            </Text>
          </Heading>
          <VStack align="start">
            <Text>
              Transform your ideas into powerful AI-driven workflows with
              Plurally, the platform that lets you design, deploy, and manage AI
              processes without writing a single line of code.
            </Text>
            <Text>
              With hundreds of pre-built blocks and the ability to create custom
              ones, your possibilities are limitless. Start from one of our
              templates!
            </Text>
          </VStack>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
            <Button
              size={"lg"}
              px={6}
              colorScheme="brand"
              as={Link}
              href="/auth"
              leftIcon={<MdRocket />}
            >
              Get started
            </Button>
            <Button
              size={"lg"}
              px={6}
              variant="outline"
              colorScheme="brand"
              as={Link}
              leftIcon={<ExternalLinkIcon />}
              isExternal
              href={requestDemoHref}
            >
              Request a demo
            </Button>
          </Stack>
        </Stack>
        <VStack
          flex={2}
          justify={"center"}
          align={"center"}
          w={"full"}
          boxShadow={"2xl"}
        >
          <Text p={4} fontSize="sm">
            The following is a simple flow that reads incoming emails and
            answers them using a GPT model connected to a knowledge database (
            <Link
              fontWeight="bold"
              href="https://aws.amazon.com/what-is/retrieval-augmented-generation/"
            >
              RAG
            </Link>
            ). Hover on the blocks to see explanations about what each block
            does!
          </Text>
          <Box height={"500px"} width={"full"} overflow={"hidden"}>
            <ReactFlowProvider>
              <Demo />
            </ReactFlowProvider>
          </Box>
        </VStack>
      </Stack>

      {/* <Text>
              Flows are built by connecting blocks together to form a pipeline.
              This intuitive way of building AI flows is accessible to anyone,
              regardless of their technical background and gives very fine
              control over the logic.
            </Text> */}
    </Container>
  );
};

const LandingPage = () => {
  return (
    <>
      <Hero />
      <Box id="use-cases">
        <UseCases />
      </Box>
      <Box id="features">
        <Features />
      </Box>
      <Box id="pricing">
        <Pricing />
      </Box>
      <Box id="contact">
        <Contact />
      </Box>
    </>
  );
};

export default LandingPage;
