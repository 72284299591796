"use client";

import {
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
} from "@chakra-ui/react";
import { redirectIfNotAuthorized } from "src/lib/utils";
import { Link } from "react-router-dom";
import useCurrentUser from "src/components/useCurrentUser";
import NotifsWidget from "./notifs-widget";

export const NavLink = (props: Props) => {
  const { children, href } = props;
  const isActive = window.location.pathname === href;
  return (
    <Box
      fontWeight="bold"
      px={2}
      py={1}
      rounded={"md"}
      bg={isActive ? "gray.200" : "inherit"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      as={"a"}
      href={href}
    >
      {children}
    </Box>
  );
};
const MobileNav = ({ links, height, ...rest }: MobileProps) => {
  const { data: currentUser, error } = useCurrentUser();
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const bg = useColorModeValue("white", "gray.900");

  if (!currentUser) {
    if (error) {
      redirectIfNotAuthorized(error);
    }
    return "";
  }
  const fullname = `${currentUser.firstname} ${currentUser.lastname}`;
  return (
    <Flex
      ml={{ base: 0, md: 0 }}
      px={{ base: 4, md: 4 }}
      height={height}
      alignItems="center"
      bg={bg}
      borderBottomWidth="1px"
      borderBottomColor={borderColor}
      justifyContent={{ base: "space-between" }}
      {...rest}
    >
      <Heading size="lg" as={Link} to="/">
        Plurally
      </Heading>
      <HStack spacing={8} alignItems={"center"}>
        <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
          {links.map((link) => (
            <NavLink href={link.href} key={`top-${link.name}`}>
              {link.name}
            </NavLink>
          ))}
        </HStack>
      </HStack>
      <HStack spacing={{ base: "0", md: "6" }}>
        <NotifsWidget />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} name={fullname} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{fullname}</Text>
                  {/* <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text> */}
                </VStack>
                {/* <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box> */}
              </HStack>
            </MenuButton>
            <MenuList bg={bg} borderColor={borderColor}>
              {links.map((link) => (
                <Box key={link.href} display={{ md: "none" }}>
                  <Link to={link.href}>
                    <MenuItem>{link.name}</MenuItem>
                  </Link>
                </Box>
              ))}
              <Link to="/account">
                <MenuItem>Account</MenuItem>
              </Link>
              <MenuDivider />
              <Link to="/auth/logout?next=/&auth=0">
                <MenuItem>Sign out</MenuItem>
              </Link>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default MobileNav;
