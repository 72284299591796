import Modal from "src/components/Modal";
import {
  Alert,
  FormLabel,
  FormControl,
  Switch,
  Button,
  Text,
  VStack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React from "react";

import axios, { getAxiosParams } from "src/lib/axios.config";
import { FlowsApi, SubscriptionsApi, UsersApi } from "typescript-axios";
import { toastPromise } from "src/JSONForm";
import { useSearchParams } from "react-router-dom";
const SUBS_API = new SubscriptionsApi(getAxiosParams(), undefined, axios);
const USERS_API = new UsersApi(getAxiosParams(), undefined, axios);
const FLOWS_API = new FlowsApi(getAxiosParams(), undefined, axios);

export const canCreateNewFlow = async (
  currentNumFlows?: int
): Promise<boolean> => {
  if (currentNumFlows === undefined) {
    currentNumFlows = await FLOWS_API.readMeApiV1FlowsMeGet().then(
      (res) => res.data.length
    );
  }
  return USERS_API.readMeApiV1UsersMeGet()
    .then((res) => {
      let limit = 1;
      if (res.data.current_plan) {
        limit = 5;
      }
      if (currentNumFlows >= limit) {
        return false;
      }
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

const SubscribeModal = ({ onSuccess, ...rest }) => {
  const toast = useToast();
  const [isYearly, setIsYearly] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const planId = isYearly
    ? import.meta.env.VITE_STRIPE_INDIVIDUAL_YEARLY_EUR
    : import.meta.env.VITE_STRIPE_MONTHLY_YEARLY_EUR;

  const price = isYearly
    ? import.meta.env.VITE_STRIPE_INDIVIDUAL_YEARLY_EUR_PRICE
    : import.meta.env.VITE_STRIPE_MONTHLY_YEARLY_EUR_PRICE;
  const successUrl = window.location.href;

  const getUrlAndRedirect = () => {
    setIsLoading(true);
    toastPromise(
      SUBS_API.checkoutApiV1SubscriptionsCheckoutGet(
        planId,
        successUrl,
        successUrl
      )
        .then((res) => {
          window.location.href = res.data;
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
          throw err;
        }),
      toast
    );
  };

  React.useEffect(() => {
    const subRef = searchParams.get("subRef");
    if (subRef) {
      toast({
        title: "One moment...",
        status: "info",
        duration: 10000,
      });
      SUBS_API.checkSubscriptionApiV1SubscriptionsCheckRefGet(subRef)
        .then((res) => {
          toast.closeAll();
          if (res.data.current_plan) {
            toast({
              title: "Subscription successful!",
              status: "success",
            });
            onSuccess(res.data.current_plan);
          } else {
            toast({
              title: "Could not check subscription",
              status: "warning",
            });
          }
        })
        .catch((err) => {
          toast({
            title: "An error occurred",
            status: "error",
          });
          console.error(err);
        })
        .finally(() => {
          searchParams.delete("subRef");
          setSearchParams(searchParams, { replace: true });
        });
    }
  }, []);

  return (
    <Modal header="Subscribe" {...rest}>
      <VStack>
        <Text>
          You've reached the limit of free flows. Subscribe to unlock more!
        </Text>
        <Alert variant="left-accent" status="info" w="100%">
          <Text>
            We offer a 7-days money-back guarantee, <b>no questions asked!</b>
          </Text>
        </Alert>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="is-yearly-subscription" mb="0">
            Save 30% with yearly subscription
          </FormLabel>
          <Switch
            id="is-yearly-subscription"
            isChecked={isYearly}
            onChange={() => setIsYearly(!isYearly)}
            colorScheme="brand"
            size="lg"
          />
        </FormControl>
        {searchParams.get("subRef") ? (
          <Spinner />
        ) : (
          <Button
            isDisabled={Boolean(isLoading || searchParams.get("subRef"))}
            colorScheme="brand"
            size="lg"
            mt={4}
            w="100%"
            onClick={getUrlAndRedirect}
          >
            Subscribe for {price}
            €/month
          </Button>
        )}
      </VStack>
    </Modal>
  );
};
export default SubscribeModal;
