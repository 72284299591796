import { AuthApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";
import GoogleSignInButton from "src/components/auth/google-signin";
import {
  Box,
  Button,
  Heading,
  Spinner,
  VStack,
  Link,
  useToast,
} from "@chakra-ui/react";
import cookie from "cookie"; // eslint-disable-line
import { useSearchParams } from "react-router-dom";
import React from "react";
import { FaFacebook } from "react-icons/fa";

const AUTH_API = new AuthApi(getAxiosParams(), undefined, axios);

const FacebookSigninButton = ({ isLoading, setIsLoading }) => {
  const toast = useToast();
  const [isSdkLoaded, setIsSdkLoaded] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const APP_ID = "1759727244839762";
  const API_VERSION = "v20.0";
  React.useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: APP_ID,
        xfbml: true,
        version: API_VERSION,
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js"; // eslint-disable-line
      fjs.parentNode.insertBefore(js, fjs); // eslint-disable-line
    })(document, "script", "facebook-jssdk");

    const checkSdkLoaded = setInterval(() => {
      if (window.FB) {
        // eslint-disable-line
        clearInterval(checkSdkLoaded);
        // not super clean, but want to start fresh every time

        if (searchParams.get("progress")) {
          setIsSdkLoaded(true);
        } else {
          window.FB.getLoginStatus(function () {
            // eslint-disable-line
            setIsSdkLoaded(true);
          });
        }
      }
    }, 500);

    return () => clearInterval(checkSdkLoaded);
  }, [APP_ID, API_VERSION]);
  return (
    <>
      <div id="fb-root"></div>
      <Button
        w="full"
        bg="#3b5998"
        color="white"
        isDisabled={!isSdkLoaded || isLoading}
        leftIcon={<FaFacebook />}
        onClick={() => {
          3;
          setIsLoading(true);
          toast({
            title: "Signing in with Facebook",
            description: "Please wait...",
            status: "info",
            isClosable: true,
          });
          window.FB.login(
            // eslint-disable-line
            function (response) {
              if (response.authResponse) {
                console.debug(response.authResponse.accessToken);
                AUTH_API.authFacebookApiV1AuthAuthFacebookGet(
                  response.authResponse.accessToken
                )
                  .then(() => {})
                  .finally(() => setIsLoading(false));
              } else {
                setIsLoading(false);
                toast.closeAll();
                toast({
                  title: "Authentication failed",
                  description: "Please try again.",
                  status: "error",
                  isClosable: true,
                });
              }
            },
            {
              scope: "email,public_profile",
            }
          );
        }}
      >
        Sign in with Facebook
      </Button>
    </>
  );
};
const Auth = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const next = searchParams.get("next");

  React.useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      searchParams.delete("code");
      setSearchParams(searchParams, { replace: true });
      setIsLoading(true);
      AUTH_API.authGoogleApiV1AuthAuthGoogleGet(redirectUrl, code).finally(() =>
        setIsLoading(false)
      );
    }
  }, [searchParams]);

  React.useEffect(() => {
    if (!isLoading) {
      const accessToken = cookie.parse(document.cookie).token;
      if (accessToken) {
        // user is logged in
        setIsLoading(true);
        window.location.href = next || "/studio";
      }
    }
  }, [isLoading]);

  const redirectUrl = window.location.origin + window.location.pathname;

  return (
    <VStack align={"center"}>
      <Box mb={4}>
        <Heading size="lg" textAlign={"center"}>
          Sign in or create an account
        </Heading>
      </Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <GoogleSignInButton
            onClick={() => {
              AUTH_API.loginGoogleApiV1AuthLoginGoogleGet(redirectUrl).then(
                (res) => {
                  window.location.href = res.data.url;
                }
              );
            }}
          />
          <FacebookSigninButton
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </>
      )}
      <Button as={Link} variant="link" href="/">
        Back
      </Button>
    </VStack>
  );
};

export default Auth;
