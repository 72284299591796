import type { UserRead, ValidationError } from "typescript-axios";

export function dataUriToFile(dataURI: string): File {
  // Find the base64 part and the filename in the data URI
  const matches = dataURI.match(/^data:(.*);name=(.*);base64,(.*)$/);
  if (!matches) {
    throw new Error("Invalid data URI");
  }

  const mimeType = matches[1];
  const fileName = matches[2];
  const base64String = matches[3];

  // Decode the base64 string
  const byteString = atob(base64String);

  // Write the bytes of the string to a typed array
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    int8Array[i] = byteString.charCodeAt(i);
  }

  // Create a blob from the typed array
  const blob = new Blob([arrayBuffer], { type: mimeType });

  // Create a file from the blob
  return new File([blob], fileName, { type: mimeType });
}

export const logout = (setUser: (user: UserRead | null) => void) =>
  fetch("/api/logout/").then(() => setUser(null));

type ApiResponseError = {
  response?: {
    data?: {
      detail?: string | ValidationError;
    };
  };
};

export const getReadableApiError = (
  e: TypeError | ApiResponseError | { message: string; detail?: string }
): string => {
  // Handle ApiResponseError structure
  if ("response" in e && e.response?.data?.detail) {
    const { detail } = e.response.data;
    if (typeof detail === "string") {
      return detail;
    }
    if (typeof detail === "object" && "msg" in detail) {
      return detail.msg;
    }
  }

  // Handle error with message property
  if ("message" in e) {
    return e.message;
  }

  // Handle error with detail property directly on e
  if ("detail" in e && typeof e.detail === "string") {
    return e.detail;
  }

  return "Something went wrong";
};

export const redirectIfNotAuthorized = (e) => {
  if (e.response.status === 401 || e.response.status === 403) {
    // avoid infinite loop
    const next =
      window.location.pathname === "/auth/logout"
        ? "/"
        : window.location.pathname;

    window.location.href = `/auth/logout?next=${next}`;
  } else {
    throw e;
  }
};

export function inlineRefs(schema, rootSchema = schema) {
  // A function to replace all $refs with the corresponding inlined definitions
  function resolveRef(ref, schema) {
    const refPath = ref.split("/").slice(1); // Remove the leading '#' and split the path
    let resolved = schema;

    for (const part of refPath) {
      if (resolved[part]) {
        resolved = resolved[part];
      } else {
        throw new Error(`Reference ${ref} not found in schema`);
      }
    }

    return resolved;
  }

  function inlineSchema(obj, rootSchema) {
    if (Array.isArray(obj)) {
      return obj.map((item) => inlineSchema(item, rootSchema));
    } else if (typeof obj === "object" && obj !== null) {
      if (obj.$ref) {
        // If $ref exists, resolve and replace it
        const refSchema = resolveRef(obj.$ref, rootSchema);
        delete obj.$ref;
        const merged = { ...refSchema, ...obj }; // Merge the resolved schema with the current object
        return inlineSchema(merged, rootSchema); // Inline the resolved schema
      } else {
        // Recursively inline for nested objects
        const newObj = {};
        for (const key in obj) {
          newObj[key] = inlineSchema(obj[key], rootSchema);
        }
        return newObj;
      }
    } else {
      // Primitive values (strings, numbers, etc.)
      return obj;
    }
  }

  // Start inlining from the root schema
  return inlineSchema(schema, rootSchema);
}
