import useSWR from "swr";
import axios, { getAxiosParams } from "src/lib/axios.config";
import { Notif, NotifsApi } from "typescript-axios";
const NOTIFS_API = new NotifsApi(getAxiosParams(), undefined, axios);

function useNotifs() {
  const { data, error, isLoading, mutate } = useSWR<Notif[]>(
    `/api/notifs/me`,
    () => NOTIFS_API.readMeApiV1NotifsMeGet().then((res) => res.data),
    { refreshInterval: 60000 } // 1 minute
  );

  return {
    notifs: data,
    isLoading,
    isError: error,
    mutate,
  };
}

export default useNotifs;
