import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Spinner,
  StackDivider,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaFacebook } from "react-icons/fa";
import axios, { getAxiosParams } from "src/lib/axios.config";
import { getReadableApiError } from "src/lib/utils";
import { AuthApi } from "typescript-axios";

const AUTH_API = new AuthApi(getAxiosParams(), undefined, axios);

interface InstagramAccount {
  id: string;
  username: string;
}

const FacebookSignin = ({ configId }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [accessToken, setAccessToken] = React.useState<string | null>(null);
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [instagramAccounts, setInstagramAccounts] = React.useState<
    InstagramAccount[] | null
  >(null);

  const connectInstagramAccount = (instagramAccountId: string) => {
    setIsLoading(true);
    if (!accessToken) {
      throw new Error("No access token found when connecting page");
    }
    AUTH_API.connectInstagramAccountApiV1AuthMetaConnectInstagramAccountPost({
      instagram_business_account_id: instagramAccountId,
      access_token: accessToken,
      config_id: configId,
    })
      .then(() => {
        setIsSuccess(true);
      })
      .catch((e) => {
        window.FB.logout(() => null);
        setIsLoading(false);
        const readable = getReadableApiError(e);
        toast({
          title: "Error connecting Instagram account",
          description: getReadableApiError(e),
          status: "error",
          isClosable: true,
        });
        if (!readable.includes("You already have connected an account.")) {
          // unknown error, log it
          console.error(e);
        }
        setInstagramAccounts(null);
      });
  };

  const toast = useToast();

  useEffect(() => {
    const sdkCheckInterval = setInterval(() => {
      if (window.FB) {
        setIsLoading(false);
        clearInterval(sdkCheckInterval);
      }
    }, 500);

    return () => clearInterval(sdkCheckInterval);
  }, []);

  if (isSuccess) {
    return (
      <Alert status="success" variant="left-accent">
        <AlertIcon />
        <AlertDescription>
          You have successfully authenticated. You can close this window now.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <VStack align="center" spacing="4">
        <Text>
          You need to connect your Facebook account to use this feature.
          <br />
          You will need to explicitly provide permissions for Plurally to manage
          your Instagram Business Account.
          <br />
          <b>Make sure that you allow pop-ups for this site.</b>
        </Text>
        {(isLoading || !configId) && <Spinner />}
        {!isLoading && instagramAccounts && (
          <VStack align="stretch" divider={<StackDivider />}>
            <Text>Choose an account to connect with:</Text>
            {instagramAccounts.map((instagramAccount) => (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                key={instagramAccount.id}
              >
                <Heading size="sm" me="2">
                  {instagramAccount.username}
                </Heading>
                <Button
                  size="xs"
                  colorScheme="brand"
                  onClick={() => connectInstagramAccount(instagramAccount.id)}
                >
                  Connect
                </Button>
              </Flex>
            ))}
            <VStack mt="4" fontSize="xs">
              <Text>Cannot find the account you are looking for?</Text>
              <Button
                variant="link"
                size="xs"
                onClick={() => {
                  window.FB.logout(() => {
                    setIsLoading(false);
                  });
                  setIsLoading(true);
                  setInstagramAccounts(null);
                }}
              >
                Connect to another account
              </Button>
            </VStack>
          </VStack>
        )}
        {!isLoading && !instagramAccounts && (
          <Button
            bg="#3b5998"
            color="white"
            leftIcon={<FaFacebook />}
            onClick={() => {
              toast({
                title: "Signing in with Facebook",
                description: "Please wait...",
                status: "info",
                isClosable: true,
              });
              setIsLoading(true);
              window.FB.login(
                function (response) {
                  if (response.authResponse) {
                    setAccessToken(response.authResponse.accessToken);
                    console.debug(response.authResponse.accessToken);
                    window.FB.api(
                      "/me/accounts",
                      "GET",
                      { fields: "instagram_business_account{id,username}" },
                      function (response) {
                        setIsLoading(false);
                        if (response.data.length === 0) {
                          toast.closeAll();
                          toast({
                            title: "No instagram accounts found",
                            description:
                              "In order to use this service, you need to have a business Instagram account connected to your Facebook page. If you have any questions, please contact us.",
                            status: "error",
                            isClosable: true,
                          });
                          window.FB.logout(() => null);
                        } else {
                          setInstagramAccounts(
                            response.data.map(
                              (d) => d.instagram_business_account
                            )
                          );
                        }
                      }
                    );
                  } else {
                    setIsLoading(false);
                    toast.closeAll();
                    toast({
                      title: "Authentication failed",
                      description: "Please try again.",
                      status: "error",
                      isClosable: true,
                    });
                  }
                },
                {
                  config_id: configId,
                }
              );
            }}
          >
            Sign in with Facebook
          </Button>
        )}
      </VStack>
    </>
  );
};

export default FacebookSignin;
