import useSWR from "swr";
import type { UserRead } from "typescript-axios";
import { UsersApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";
import { redirectIfNotAuthorized } from "src/lib/utils";

const USERS_API = new UsersApi(getAxiosParams(), undefined, axios);

const useCurrentUser = () => {
  const fetcher = () =>
    USERS_API.readMeApiV1UsersMeGet()
      .then((res) => res.data)
      .catch((e) => {
        redirectIfNotAuthorized(e);
      });
  return useSWR<UserRead>(`/users/me`, fetcher, {
    refreshInterval: 1000 * 60 * 5,
    dedupingInterval: 1000 * 60 * 5,
  });
};

export default useCurrentUser;
