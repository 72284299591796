import {
  VStack,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Button,
  StackDivider,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import React from "react";
import axios, { getAxiosParams } from "./lib/axios.config";
import { AuthApi } from "typescript-axios";
import cookie from "cookie";

const AUTH_API = new AuthApi(getAxiosParams(), undefined, axios);

const AuthAdmin = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!isLoading) {
      const accessToken = cookie.parse(document.cookie).token;
      if (accessToken) {
        setIsLoading(true);
        window.location.href = "/studio";
      }
    }
  }, [isLoading]);

  const onSubmit = () => {
    setIsLoading(true);
    AUTH_API.authEmailApiV1AuthAuthEmailPost(email, password).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <VStack align="stretch">
        <Heading size="sm" as="h3">
          Admin
        </Heading>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <FormControl isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormHelperText>We'll never share your email.</FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <StackDivider />
            <Button w="full" colorScheme="brand" size="lg" type="submit">
              Sign in
            </Button>
          </>
        )}
      </VStack>
    </form>
  );
};

export default AuthAdmin;
