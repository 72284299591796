import "./index.css";

import * as React from "react";
import { Box, ChakraProvider } from "@chakra-ui/react";
import * as ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "src/App";
import customTheme from "src/styles/theme";
import Auth from "src/Auth";
import AuthScopes from "src/AuthScopes";
import AuthMeta from "src/AuthMeta";
import AuthAdmin from "src/AuthAdmin";
import Account from "src/Account";
import Home from "src/Home";
import Logout from "src/Logout";
import { Layout as HomeLayout } from "src/home/layout";
import StudioLayout from "src/studio/layout";
import AuthLayout from "src/studio/authlayout";
import LandingPage from "src/home/Landing";
import Privacy from "./privacy";
import Explore from "./explore";
import ExploreEdit from "./ExploreEdit";
import InstaDMEscalatationPage from "./components/insta-dm-escalation";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider
      theme={customTheme}
      toastOptions={{
        defaultOptions: {
          position: "top-right",
          variant: "solid",
          isClosable: true,
        },
      }}
    >
      <BrowserRouter>
        <Box className="App">
          <Routes>
            <Route path="/" element={<HomeLayout />}>
              <Route index element={<LandingPage />} />
            </Route>
            <Route path="/studio" element={<StudioLayout />}>
              <Route index element={<Home />} />
              <Route path="/studio/:flowId" element={<App />} />
            </Route>
            <Route path="/account" element={<StudioLayout />}>
              <Route index element={<Account />} />
            </Route>
            <Route path="/escalations" element={<StudioLayout />}>
              <Route
                path="/escalations/:escalationId"
                element={<InstaDMEscalatationPage />}
              />
            </Route>
            <Route path="/explore" element={<StudioLayout />}>
              <Route index element={<Explore />} />
              <Route path="/explore/:templateId" element={<ExploreEdit />} />
            </Route>
            <Route path="/auth" element={<AuthLayout />}>
              <Route index element={<Auth />} />
              <Route path="/auth/scopes" element={<AuthScopes />} />
              <Route path="/auth/meta" element={<AuthMeta />} />
              <Route path="/auth/admin" element={<AuthAdmin />} />
              <Route path="/auth/logout" element={<Logout />} />
            </Route>
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);
