import {
  Button,
  ListItem,
  UnorderedList,
  useToast,
  VStack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { MdPlayArrow, MdStop } from "react-icons/md";
import { toastPromise } from "src/JSONForm";
import {
  getIssues,
  getOauthUrl,
  startFlow,
  stopFlow,
} from "src/flowActions";
import { FlowRead, UsersApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";
import OAuthModalInfo from "src/components/oauth";

interface StartButtonProps {
  flowId: string;
  onSuccess: (flow: FlowRead) => void;
}

export const StartButton = ({
  flow,
  nodeTypes,
  onSuccess,
  ...rest
}: StartButtonProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [oauthUrl, setOauthUrl] = React.useState<string | null>(null);

  const toast = useToast();

  const isEmpty = flow.flow_json?.nodes?.length === 0;

  return (
    <>
      <OAuthModalInfo oauthUrl={oauthUrl} onClose={() => setOauthUrl(null)} />
      <Tooltip label="Flow empty, you must first add blocks">
        <Button
          isDisabled={isLoading || isEmpty}
          onClick={() => {
            getOauthUrl(
              flow.flow_json?.nodes.map((n) => n.id.kls),
              nodeTypes
            ).then((oauthUrl) => {
              if (oauthUrl) {
                setOauthUrl(oauthUrl);
              } else {
                setIsLoading(true);
                toast({
                  status: "loading",
                  title: "Starting flow...",
                });
                startFlow(flow.id)
                  .then((flow) => {
                    toast.closeAll();
                    onSuccess(flow);
                    toast({
                      status: "success",
                      title: "Flow is running",
                    });
                  })
                  .catch((e) => {
                    getIssues(flow.id).then((issues) => {
                      if (issues.is_valid) {
                        toast.closeAll();
                        toast({
                          title: "Flow failed to start flow",
                          description: e.message,
                          status: "error",
                          duration: 10000,
                        });
                        console.error(e);
                      } else {
                        toast.closeAll();
                        toast({
                          title: "Flow failed to start",
                          description: (
                            <VStack align="stretch">
                              <UnorderedList>
                                {issues.issues.map((issue) => (
                                  <ListItem key={issue}>{issue}</ListItem>
                                ))}
                              </UnorderedList>
                              <Text>Head to the studio to fix the issues</Text>
                            </VStack>
                          ),
                          status: "error",
                          duration: 10000,
                        });
                      }
                    });
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }
            });
          }}
          colorScheme="green"
          {...rest}
          leftIcon={<MdPlayArrow />}
        >
          Start
        </Button>
      </Tooltip>
    </>
  );
};

export const StopButton = ({ flowId, onSuccess }: StartButtonProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const toast = useToast();
  return (
    <Button
      id="stop-btn"
      isDisabled={isLoading}
      size="sm"
      onClick={() => {
        setIsLoading(true);
        toastPromise(
          stopFlow(flowId)
            .catch((e) => {
              console.error(e);
              throw e;
            })
            .then(onSuccess)
            .finally(() => {
              setIsLoading(false);
            }),
          toast,
          { success: { title: "Flow stopped" } }
        );
      }}
      colorScheme="red"
      variant="outline"
      leftIcon={<MdStop />}
    >
      Stop
    </Button>
  );
};
