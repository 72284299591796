import { NodeType } from "typescript-axios";
import { useDnD } from "src/DnDContext";
import {
  Box,
  Badge,
  Button,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  List,
  ListItem,
  ListIcon,
  IconButton,
  useDisclosure,
  Text,
  HStack,
  Flex,
  Drawer,
  DrawerContent,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { getUserFriendlyName } from "src/nodesFriendly";
import { MdDragIndicator, MdHelp } from "react-icons/md";
import { FiHelpCircle } from "react-icons/fi";
import Modal from "src/components/Modal";
import NodeSummary from "src/studio/nodeSummary";
import React from "react";
import classNames from "classnames";

interface SidebarProps {
  nodeTypes: NodeType[] | null;
}

const groupByAttr = (items, attr_key) => {
  if (items === null) {
    return {};
  }
  const grouped = items.reduce((acc, item) => {
    const group = item[attr_key];
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(item);
    return acc;
  }, {});

  for (const group in grouped) {
    grouped[group].sort((a, b) => a.kls.localeCompare(b.kls));
  }
  return grouped;
};

const Sidebar = ({ nodeTypes }: SidebarProps) => {
  const navbarHeight = "80px"; // FIXME: harcoded
  return (
    <Box minH={`calc(100vh - ${navbarHeight})`} overflowY="scroll">
      <SidebarContent nodeTypes={nodeTypes} />
    </Box>
  );
};

const SidebarContent = ({ nodeTypes, ...rest }: SidebarProps) => {
  const [_, setType] = useDnD();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onDragStart = React.useCallback((event, nodeType) => {
    setType(nodeType);
    event.dataTransfer.effectAllowed = "move";
  }, []);

  const [selectedNodeType, setSelectedNodeType] =
    React.useState<NodeType | null>(null);
  const groupedItems = useMemo(
    () => groupByAttr(nodeTypes, "group"),
    [nodeTypes]
  );

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderLeft="1px"
      borderLeftColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: "60" }}
      p={2}
      h="full"
    >
      <Modal
        size="xl"
        isOpen={selectedNodeType && isOpen}
        onClose={onClose}
        header={
          <HStack alignItems="center">
            <Text>Block Info</Text>
            <Button
              leftIcon={<MdHelp />}
              size="sm"
              onClick={() => setIsOpen((v) => !v)}
            >
              Help
            </Button>
          </HStack>
        }
      >
        <NodeSummary
          nodeType={selectedNodeType}
          outputSchema={selectedNodeType?.outputs}
        />
      </Modal>
      {nodeTypes === null ? (
        <Spinner />
      ) : (
        <Box fontSize="xs">
          <List>
            <ListItem>
              <Text as="h2" fontSize="xl">
                Blocks
              </Text>
            </ListItem>
          </List>
          <List className="description">
            <ListItem>
              <ListIcon as={MdDragIndicator} />
              You can drag these blocks to build your flow.
            </ListItem>
            <ListItem>
              <ListIcon as={FiHelpCircle} />
              Click on them to see information about them.
            </ListItem>
          </List>
          <Accordion allowMultiple={false} defaultIndex={[0]}>
            {Object.entries(groupedItems).map(([group, items], ix) => {
              const isDisabled = ["crm", "eshop"].includes(
                group.toLocaleLowerCase()
              );
              return (
                <AccordionItem key={group} isDisabled={isDisabled}>
                  <Box id={ix == 0 ? "first-group" : undefined}>
                    <AccordionButton>
                      <Flex justify="space-between" w="full">
                        <Box flex="1" textAlign="left" fontSize="sm">
                          {group}
                        </Box>
                        {isDisabled && (
                          <Badge colorScheme="brand" variant="subtle">
                            soon
                          </Badge>
                        )}
                      </Flex>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <VStack>
                        {items.map((nodeType, ixNode) => (
                          <Flex
                            w="full"
                            onDragStart={(event) =>
                              onDragStart(event, nodeType)
                            }
                            draggable
                            cursor="grab"
                            key={nodeType.kls}
                            p={2}
                            textAlign="center"
                            border="1px solid black"
                            alignItems="center"
                            justifyContent={"space-between"}
                            borderRadius={4}
                            bg="white"
                          >
                            <MdDragIndicator />
                            {getUserFriendlyName(nodeType.kls)}
                            <Box
                              id={
                                ixNode == 0 && ix == 0
                                  ? "first-node-info"
                                  : undefined
                              }
                            >
                              <IconButton
                                size="xs"
                                m={0}
                                p={0}
                                variant="ghost"
                                aria-label="open block info"
                                onClick={() => {
                                  setSelectedNodeType(nodeType);
                                  onOpen();
                                }}
                                icon={<FiHelpCircle />}
                              />
                            </Box>
                          </Flex>
                        ))}
                      </VStack>
                    </AccordionPanel>
                  </Box>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box>
      )}
    </Box>
  );
};

export default Sidebar;
