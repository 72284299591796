"use client";

import {
  Container,
  SimpleGrid,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  Center,
  Spinner,
  GridItem,
  VStack,
  Button,
  Link,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { ReactFlow, Controls, Background } from "@xyflow/react";
import React from "react";
import { createCustomNodes, getEdgesAndNodesFromJson } from "src/appUtils";
import SCHEMAS from "src/res/demo-node-types.json";
import TEMPLATE_JSON from "src/res/template-hotel.json";
import { MdCheck, MdMail, MdRocket } from "react-icons/md";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FlowsApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";

const FLOWS_API = new FlowsApi(getAxiosParams(), undefined, axios);

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}
const Feature = ({ text, subtext, icon, iconBg }: FeatureProps) => {
  return (
    <VStack align="stretch">
      <Stack direction={"row"} align={"center"}>
        <Flex
          w={8}
          h={8}
          align={"center"}
          justify={"center"}
          rounded={"full"}
          bg={iconBg}
        >
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
      <Text color={"gray.500"}>{subtext}</Text>
    </VStack>
  );
};

const UseCases = () => {
  const [demoReady, setDemoReady] = React.useState(false);
  const [customNodes, setCustomNodes] = React.useState(null);

  const [nodes, setNodes] = React.useState();
  const [edges, setEdges] = React.useState();

  React.useEffect(() => {
    FLOWS_API.demoHotelHealthApiV1FlowsDemoHotelHealthGet()
      .then((res) => {
        setDemoReady(true);
      })
      .catch((err) => {
        // ignore
      });
  });

  React.useEffect(() => {
    if (SCHEMAS && TEMPLATE_JSON) {
      const customNodes = createCustomNodes(SCHEMAS, null, null, null, true);
      setCustomNodes(customNodes);
      const [edges, nodes] = getEdgesAndNodesFromJson(TEMPLATE_JSON);
      setNodes(nodes);
      setEdges(edges);
    }
  }, [SCHEMAS, TEMPLATE_JSON]);

  return (
    <Container id="use-cases" maxW={"100%"} zIndex={10} position={"relative"} p={{ md: "16" }}>
      <SimpleGrid columns={{ base: 1, md: 3, xl: 6 }} spacing={10}>
        <GridItem as={Stack} spacing={4} colSpan={{ base: 1, md: 1, xl: 2 }}>
          <Text
            textTransform={"uppercase"}
            color={"blue.400"}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("blue.50", "blue.900")}
            p={2}
            alignSelf={"flex-start"}
            rounded={"md"}
          >
            Example Flow
          </Text>
          <Heading>AI Hotel Manager</Heading>
          <Text fontSize={"lg"}>
            Here’s how Plurally can automate customer support at a hotel: This
            workflow reads incoming emails, determines if human intervention is
            necessary, and responds automatically using AI. When needed, it
            forwards emails to a (human) hotel manager.
          </Text>
          <Heading mt={4} size="lg">
            Key Features of this Flow:
          </Heading>
          <Stack
            spacing={2}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature
              icon={<Icon as={MdCheck} color={"green.500"} w={5} h={5} />}
              iconBg={useColorModeValue("green.100", "green.900")}
              text="Reads incoming email"
              subtext="Flow will execute when an email is received."
            />
            <Feature
              icon={<Icon as={MdCheck} color={"green.500"} w={5} h={5} />}
              iconBg={useColorModeValue("green.100", "green.900")}
              text="Classify intent"
              subtext="Understand what the email is about."
            />
            <Feature
              icon={<Icon as={MdCheck} color={"green.500"} w={5} h={5} />}
              iconBg={useColorModeValue("green.100", "green.900")}
              text="AI-Powered Responses"
              subtext={
                <Text>
                  Leverages knowledge bases to redact accurate, human-like
                  responses.{" "}
                  <strong>
                    See the knowledge it uses{" "}
                    <Button
                      as={Link}
                      colorScheme="brand"
                      isExternal
                      variant="link"
                      href="https://docs.google.com/spreadsheets/d/1XjPNJN0h-Y_q0Y6RdwnTVfeKBVxM8wuAwUcB6cyGHKk/edit?usp=sharing"
                    >
                      here
                      <ExternalLinkIcon mx="2px" />
                    </Button>
                  </strong>
                  .
                </Text>
              }
            />
            <Feature
              icon={<Icon as={MdCheck} color={"green.500"} w={5} h={5} />}
              iconBg={useColorModeValue("green.100", "green.900")}
              text="Conditional Execution"
              subtext="Escalates to a human when required."
            />
            {demoReady ? (
              <>
                <Text fontSize="lg">
                  See the flow in action by sending an email to{" "}
                  <Button
                    size="lg"
                    as={Link}
                    variant="link"
                    colorScheme="brand"
                    href="mailto:hotel@tryplurally.com?subject=Question&body=Hello%2C%0D%0A%0D%0AI%20would%20like%20to%20know%20what%20is%20your%20refund%20policy%3F%0D%0A%0D%0ABest%2C%0D%0AMelissa"
                  >
                    hotel@tryplurally.com
                  </Button>{" "}
                  or create your own flow using our AI Hotel Manager template.{" "}
                </Text>
                <Text fontSize="sm" fontStyle="italic">
                  This is not a real hotel, so do not worry about spamming!
                </Text>
              </>
            ) : (
              <Text fontSize="lg">
                Create your own flow using our AI Hotel Manager template!
              </Text>
            )}
          </Stack>
          <Stack
            spacing={{ base: 2}}
            direction={{ base: "column" }}
          >
            {demoReady && (
              <Button
                leftIcon={<MdMail />}
                colorScheme="brand"
                variant="outline"
                as={Link}
                href="mailto:hotel@tryplurally.com?subject=Question&body=Hello%2C%0D%0A%0D%0AI%20would%20like%20to%20know%20what%20is%20your%20refund%20policy%3F%0D%0A%0D%0ABest%2C%0D%0AMelissa"
                size="lg"
              >
                Try This Flow By Sending An Email
              </Button>
            )}
            <Button
              leftIcon={<MdRocket />}
              colorScheme="brand"
              size="lg"
              as={Link}
              href="https://tryplurally.com/explore/47c1e2d3-735a-4f55-ab11-693c79329165"
            >
              Start Building
            </Button>
          </Stack>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 2, xl: 4 }} h="100%">
          <Flex minH="80vh" h="100%">
            {customNodes ? (
              <ReactFlow
                onNodesChange={() => null}
                onEdgesChange={() => null}
                onConnect={() => null}
                edgesFocusable={false}
                nodesDraggable={false}
                nodesConnectable={false}
                nodesFocusable={true}
                elementsSelectable={true}
                zoomOnScroll={false}
                panOnScroll={false}
                preventScrolling={false}
                nodeTypes={customNodes || {}}
                nodes={nodes}
                edges={edges}
                fitView
                maxZoom={1.2}
                minZoom={0.7}
              >
                <Background />
                <Controls />
              </ReactFlow>
            ) : (
              <Center>
                <Spinner />
              </Center>
            )}
          </Flex>
        </GridItem>
      </SimpleGrid>
    </Container>
  );
};

export default UseCases;
