import { Box, Heading, Text } from "@chakra-ui/react";

const Privacy = () => (
  <Box p="4">
    <Heading as="h1" size="md">
      Privacy Policy
    </Heading>
    <Box p="2"> Effective Date: 17/9/2024</Box>
    <Box p="2">
      At Plurally, we value your privacy and are committed to protecting your
      personal information. This Privacy Policy explains how we collect, use,
      and disclose information about our users when you visit our website, use
      our services, or engage with us.
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        1. Information We Collect
      </Heading>
      <p>
        We collect different types of information from you when you interact
        with our website. The types of information we may collect include:
      </p>
      <ul>
        <li>Personal Information</li>
        <li>Non-Personal Information</li>
        <li>Cookies and Tracking Technologies</li>
      </ul>
      <p>
        For certain features, we also access more information from third-party
        sources on a opt-in basis, such as:
      </p>
      <ul>
        <li>
          <Text fontWeight="bold" color="blue" decoration="underline">
            <a href="#google-sheets">Google Sheets</a>
          </Text>
        </li>
        <li>
          <Text fontWeight="bold" color="blue" decoration="underline">
            <a href="#facebook">Facebook Login</a>
          </Text>
        </li>
        <li>
          <Text fontWeight="bold" color="blue" decoration="underline">
            <a href="#instagram">Instagram Business Account Messaging</a>
          </Text>
        </li>
      </ul>
      Please see the specific sections in this policy for more details on how we
      use this information.
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        2. How We Use Your Information
      </Heading>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>To provide and improve our services</li>
        <li>To process transactions and communicate with you about them</li>
        <li>
          To send you updates, newsletters, and promotional content (if you have
          opted in)
        </li>
        <li>To personalize your experience on our site</li>
        <li>
          To understand how users engage with our site and improve usability
        </li>
        <li>To comply with legal obligations</li>
      </ul>
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        3. Sharing Your Information
      </Heading>
      <p>
        We do not sell your personal information to third parties. However, we
        may share your data with:
      </p>
      <ul>
        <li>Service Providers</li>
        <li>Legal Compliance</li>
        <li>Business Transfers</li>
      </ul>
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        4. Data Retention
      </Heading>
      <p>
        We retain your personal information only for as long as necessary to
        fulfill the purposes for which it was collected or as required by law.
        When your data is no longer needed, we will securely delete or anonymize
        it.
      </p>
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        5. Security
      </Heading>
      <p>
        We use industry-standard security measures to protect your personal
        information from unauthorized access, disclosure, or misuse. However, no
        method of transmission over the internet or electronic storage is
        completely secure, and we cannot guarantee the absolute security of your
        data. All data transmissions between Plurally and Google’s services are
        encrypted using HTTPS.
      </p>
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        6. Your Rights and Choices
      </Heading>
      <p>
        <strong>a) Access, Update, or Delete Your Data</strong>
        <br />
        You have the right to access, update, or request the deletion of your
        personal information at any time. To do so, please contact us using the
        information provided below.
      </p>
      <p>
        <strong>b) Opt-Out of Communications</strong>
        <br />
        If you no longer wish to receive marketing emails from us, you can opt
        out by clicking the "unsubscribe" link in the email or contacting us
        directly.
      </p>
      <p>
        <strong>c) Cookies Management</strong>
        <br />
        You can adjust your browser settings to block or delete cookies, though
        this may affect your experience on our site.
      </p>
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        7. Children's Privacy
      </Heading>
      <p>
        Our website and services are not intended for individuals under the age
        of 13. We do not knowingly collect personal information from children
        under 13. If we learn that we have inadvertently collected such
        information, we will take steps to delete it as soon as possible.
      </p>
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        8. Third-Party Links
      </Heading>
      <p>
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices or content of those external
        sites. We encourage you to review their privacy policies before
        providing any personal information.
      </p>
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        10. Changes to This Policy
      </Heading>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with the updated date. We encourage you to review
        this policy periodically to stay informed about how we are protecting
        your information.
      </p>
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        11. Third-Party Services
      </Heading>
      <Box p="2">
        <Heading as="h3" size="md" id="google-sheets">
          11.1 Google Sheets
        </Heading>
        <p>
          We may request access to your Google Sheets account through OAuth 2.0
          to read and write data. This access is used to provide you with the
          ability to import and export data from Google Sheets. We do not store
          your Google Sheets data on our servers. This feature is optional, and
          you can revoke access at any time from your Google account settings as
          well as from your Plurally's account page:{" "}
          <Text
            display="inline"
            fontWeight="bold"
            color="blue"
            decoration="underline"
          >
            <a href="https://tryplurally.com/account">
              tryplurally.com/account
            </a>
            .
          </Text>
        </p>
        <Heading as="h4" size="md">
          Scopes
        </Heading>
        <p>
          Depending on the features you wish to use, Plurally requests the
          following OAuth scopes to access your data:
        </p>
        <ul>
          <li>
            <strong>https://www.googleapis.com/auth/spreadsheets.read:</strong>{" "}
            Read-only access to Google Sheets, allowing us to read the content
            of any spreadsheet to which you grant us access.
          </li>
          <li>
            <strong>https://www.googleapis.com/auth/spreadsheets:</strong> Full
            access to Google Sheets, allowing us to read and modify the content
            of any spreadsheet to which you grant us access. These permissions
            are only used to fulfill the core functions of the app. We do not
            access any other Google user data beyond what is necessary for the
            stated purposes.
          </li>
        </ul>
        <Heading as="h4" size="md">
          Data usage
        </Heading>
        <p>
          <strong>No Third-Party Sharing:</strong> We do not share your Google
          Sheets data with any third parties.
        </p>
        <p>
          <strong>Temporary Storage:</strong> Any data retrieved from your
          Google Sheets is only processed temporarily and not stored on our
          servers.
        </p>
      </Box>

      <Box p="2">
        <Heading as="h3" size="md" id="facebook">
          11.2 Facebook Login
        </Heading>
        <p>
          If you wish to, you may authenticate to Plurally through Facebook
          Login. This secure access leverages OAuth 2.0 to authenticate your
          identity and provide you with a seamless login experience. We do not
          store your Facebook Login access tokens nor exchange them for
          long-lived ones. You can delete your account and any data associated
          with it by contacting us directly at{" "}
          <Text
            display="inline"
            fontWeight="bold"
            color="blue"
            decoration="underline"
          >
            <a href="mailto:hello@tryplurally.com">hello@tryplurally.com</a>
          </Text>
        </p>
        <Heading as="h4" size="md">
          Data Usage
        </Heading>
        <p>
          We use the information to authenticate your identity and provide you
          access to our services.
        </p>
        <p>
          We do not sell, trade, or otherwise transfer your data to third
          parties. We may share data only when required by law or when necessary
          to protect your rights or fulfill contractual obligations, after
          carefully verifying the legitimacy of the request.
        </p>
        <Heading as="h4" size="md">
          Informations Accessed
        </Heading>
        <p>
          We access information that you voluntarily provide when you authorize
          our app via Facebook and Instagram, including:
          <ul>
            <li>
              <strong>Basic Account Information</strong>: When you log in using
              Facebook Login we collect your First Name, Last Name and Email.
            </li>
          </ul>
        </p>
        <p>
          We access strictly the information necessary to provide the services
          you request.
        </p>
        <Heading as="h4" size="md">
          Permissions
        </Heading>
        <p>
          We request the following permissions to provide our services:
          <ul>
            <li>
              <strong>email</strong>: Access to your email address.
            </li>
            <li>
              <strong>public_profile</strong>: Access to your public profile
            </li>
          </ul>
        </p>
        <Heading as="h4" size="md">
          Deauthorization and Data Deletion
        </Heading>
        <p>
          Please contact us directly at{" "}
          <Text
            display="inline"
            fontWeight="bold"
            color="blue"
            decoration="underline"
          >
            <a href="https://tryplurally.com/account">
              tryplurally.com/account
            </a>
          </Text>
        </p>
      </Box>
      <Box p="2">
        <Heading as="h3" size="md" id="instagram">
          11.3 Instagram Business Account Messaging
        </Heading>
        <p>
          We may request access to your Instagram Business Account through OAuth
          2.0 to read and write data. This access is used to provide you with
          the ability to integrate your Instagram Business Account into
          Plurally. We do not store your Instagram Business Account data on our
          servers. This feature is optional, and you can revoke access at any
          time from your Instagram Settings Page as well as from your Plurally's
          account page:{" "}
          <Text
            display="inline"
            fontWeight="bold"
            color="blue"
            decoration="underline"
          >
            <a href="https://tryplurally.com/account">
              tryplurally.com/account
            </a>
          </Text>
          , or by contacting us directly at{" "}
          <Text
            display="inline"
            fontWeight="bold"
            color="blue"
            decoration="underline"
          >
            <a href="mailto:hello@tryplurally.com">hello@tryplurally.com</a>
          </Text>
        </p>
        <Heading as="h4" size="md">
          Data Usage
        </Heading>
        <p>
          We use the information collected to provide the services you request,
          such as the ability for you to manage your Instagram Business Account
          messages in Plurally.
        </p>
        <p>
          We do not sell, trade, or otherwise transfer your data to third
          parties. We may share data only when required by law or when necessary
          to protect your rights or fulfill contractual obligations, after
          carefully verifying the legitimacy of the request.
        </p>
        <Heading as="h4" size="md">
          Informations Accessed
        </Heading>
        <p>
          We access information that you voluntarily provide when you authorize
          our app via Facebook and Instagram, including:
          <ul>
            <li>
              <strong>Basic Account Information</strong>: When you log in using
              Facebook or Instagram, we collect and store basic information such
              as your name, profile picture, email, and any other data provided
              by Meta.
            </li>
            <li>
              <strong>Messaging and Inbox Data</strong>: If you authorize access
              to Instagram messages, we may collect message metadata (e.g.,
              message IDs, timestamps) to help manage your communications. This
              data is necessary for providing message-management functionality.
            </li>
            <li>
              <strong>Page and Business Data</strong>: If you manage Facebook
              Pages or Instagram accounts, we collect relevant business and page
              data (e.g., page ID, page name, roles) to facilitate interactions
              like page messaging or page-related actions.
            </li>
          </ul>
        </p>
        <p>
          We access strictly the information necessary to provide the services
          you request.
        </p>
        <Heading as="h4" size="md">
          Permissions
        </Heading>
        <p>
          We request the following permissions to provide our services:
          <ul>
            <li>
              <strong>read_page_mailboxes</strong>: Access to view your page's
              inbox.
            </li>
            <li>
              <strong>pages_messaging</strong>: Manage and send messages on
              behalf of your Facebook Page.
            </li>
            <li>
              <strong>pages_show_list</strong>: Access a list of Pages you
              manage.
            </li>
            <li>
              <strong>pages_manage_metadata</strong>: Read metadata for Pages
              and businesses.
            </li>
            <li>
              <strong>business_management</strong>: Manage business assets you
              own or control.
            </li>
            <li>
              <strong>instagram_basic</strong>: Access basic profile information
              for Instagram accounts you manage.
            </li>
          </ul>
        </p>
        <Heading as="h4" size="md">
          Deauthorization and Data Deletion
        </Heading>
        <p>
          You have the right to revoke the permissions granted to Plurally at
          any time or request the deletion of your data. You can do this from
          your Instagram Settings Page or by contacting us directly. We will
          promptly respond to all requests for data deletion and ensure that the
          relevant information is removed from our databases in compliance with
          applicable regulations
        </p>
      </Box>
    </Box>
    <Box p="2">
      <Heading as="h2" size="md">
        13. Contact Us
      </Heading>
      <p>
        If you have any questions about this Privacy Policy or our practices
        regarding your personal information, please contact us at:
      </p>
      Email:{" "}
      <Text
        display="inline"
        fontWeight="bold"
        color="blue"
        decoration="underline"
      >
        <a href="mailto:hello@tryplurally.com">hello@tryplurally.com</a>
      </Text>
    </Box>
  </Box>
);

export default Privacy;
