"use client";

import {
  Box,
  HStack,
  useColorModeValue,
  Heading,
  Center,
  SimpleGrid,
  GridItem,
  Button,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { NavLink } from "src/components/mobile-nav";
import { Outlet, Link } from "react-router-dom";
import cookie from "cookie";

interface LinkItemProps {
  name: string;
  icon: IconType;
}

const LINKS: Array<LinkItemProps> = [
  { name: "Use Cases", href: "#use-cases" },
  { name: "Features", href: "#features" },
  { name: "Pricing", href: "#pricing" },
  { name: "Demo", href: "https://calendly.com/alexvillard/15min", isExternal: true },
  { name: "Contact", href: "#contact" },
];

export const Layout = () => {
  const navbarHeight = "80px";
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const bg = useColorModeValue("white", "gray.900");

  // read cookie
  const loginToken = cookie.parse(document.cookie).token;
  return (
    <>
      <head>
        <title>Build Any AI Flow - No Code | Plurally</title>
      </head>
      <div>
        <Box as={"header"} position={"fixed"} top={0} w="100%" zIndex={1000}>
          <SimpleGrid
            w="100%"
            columns={12}
            alignItems="center"
            spacing={4}
            height={navbarHeight}
            bg={bg}
            borderBottomWidth="1px"
            borderBottomColor={borderColor}
            justifyContent="space-between"
          >
            <GridItem colSpan={{ base: 10, md: 2 }}>
              <Heading as={Link} to="/" size="lg" ps={{ base: 2, md: 16 }}>
                Plurally
              </Heading>
            </GridItem>
            <GridItem colSpan={8} display={{ base: "none", md: "flex" }}>
              <Center>
                <HStack as={"nav"} spacing={4}>
                  {LINKS.map((link) => (
                    <NavLink
                      href={link.href}
                      key={`top-${link.name}`}
                      isExternal={link.isExternal}
                    >
                      {link.name}
                    </NavLink>
                  ))}
                </HStack>
              </Center>
            </GridItem>
            <GridItem colSpan={2}>
              {loginToken ? (
                <Button colorScheme="brand" me="2" as={Link} to="/studio">
                  Dashboard
                </Button>
              ) : (
                <Button variant="link" me="2" as={Link} to="/auth">
                  Sign in
                </Button>
              )}
            </GridItem>
          </SimpleGrid>
        </Box>
        <Box
          as={"main"}
          mt={navbarHeight}
          h={`calc(100vh - ${navbarHeight})`}
          w="100%"
          display="flex"
          flexDirection="column"
        >
          <Outlet />
          <Box bg={bg}>
            <Box py={12}>
              <Center>
                <HStack spacing={4}>
                  <Link to="/privacy">Privacy</Link>
                </HStack>
              </Center>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};
