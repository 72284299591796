import { AuthApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";
import {
  Text,
  Spinner,
  VStack,
  useToast,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import React from "react";

const AUTH_API = new AuthApi(getAxiosParams(), undefined, axios);

const AuthScopes = () => {
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();

  React.useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      searchParams.delete("code");
      setSearchParams(searchParams, { replace: true });
      const scopes = localStorage.getItem("scopes");
      if (!scopes) {
        setIsSuccess(false);
        return;
      }
      localStorage.removeItem("scopes");
      AUTH_API.authApiApiV1AuthAuthGoogleApiGet(redirectUrl, code, scopes)
        .then(() => setIsSuccess(true))
        .catch((e) => {
          toast({
            title: "Authentication failed",
            description: "Please try again.",
            status: "error",
            isClosable: true,
          });
          throw e;
        });
    }
  }, [searchParams]);

  const redirectUrl = window.location.origin + window.location.pathname;

  if (isSuccess) {
    return (
      <Alert status="success" variant="left-accent">
        <AlertIcon />
        <AlertDescription>
          You have successfully authenticated. You can close this window now.
        </AlertDescription>
      </Alert>
    );
  }
  return (
    <VStack spacing={4} align={"center"}>
      <Text>Just a moment, we are authenticating you...</Text>
      <Spinner />
    </VStack>
  );
};

export default AuthScopes;
