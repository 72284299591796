"use client";

import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { MdPhone, MdEmail } from "react-icons/md";
import { BsDiscord } from "react-icons/bs";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const Contact = () => {
  const brandColor = useColorModeValue("brand.800", "brand.100");
  return (
    <Container
      bg={brandColor}
      maxW="full"
      mt={0}
      centerContent
      overflow="hidden"
    >
      <Flex>
        <Box
          bg="white"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}
        >
          <Box p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              <WrapItem>
                <Box>
                  <Heading color="gray.800">Contact</Heading>
                  <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.800">
                    Reach out for any questions or feedback!{" "}
                    <b>We answer quickly.</b>
                  </Text>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack pl={0} spacing={3} align="stretch" fontWeight={600}>
                      <HStack>
                        <MdEmail />
                        <Link isExternal href="mailto:hello@tryplurally.com">
                          hello@tryplurally.com
                          <ExternalLinkIcon mx="2px" />
                        </Link>
                      </HStack>
                      <HStack>
                        <BsDiscord />
                        <Link href="https://discord.gg/hTy7t42m" isExternal>
                          Discord
                          <ExternalLinkIcon mx="2px" />
                        </Link>
                      </HStack>
                      <HStack>
                        <MdPhone />
                        <Link href="https://calendly.com/alexvillard/15min" isExternal>
                          Schedule a call
                          <ExternalLinkIcon mx="2px" />
                        </Link>
                      </HStack>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default Contact;
