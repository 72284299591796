import type { CreateToastFnReturn } from "@chakra-ui/react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Spinner,
  useToast,
  Text,
} from "@chakra-ui/react";
import type { UiSchema } from "@rjsf/chakra-ui";
import Form from "@rjsf/chakra-ui";
import type {
  RJSFSchema,
  RegistryWidgetsType,
  DescriptionFieldProps,
} from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import React from "react";
import { getReadableApiError } from "src/lib/utils";

interface MessageOption {
  title?: string | React.ReactNode | null;
  description?: string | React.ReactNode | null;
}

interface ToastOptions {
  loading: MessageOption;
  success: MessageOption;
  error: MessageOption;
}

const defaultToastOptions: ToastOptions = {
  loading: { title: "Loading" },
  success: { title: "Success" },
  error: { title: "Something went wrong" },
};

function DescriptionFieldTemplate(props: DescriptionFieldProps) {
  const { description, id } = props;
  return (
    <Text id={id} fontSize="xs" fontStyle="italic" color="gray.500">
      {description}
    </Text>
  );
}
interface JSONFormProps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: RJSFSchema;
  onSubmit: (formData: T, schemaSubmitted: RJSFSchema) => Promise<unknown>;
  uiSchema: UiSchema;
  toastOptions?: Partial<ToastOptions>;
  initialData?: Partial<T>;
  widgets?: RegistryWidgetsType;
  onChange?: (formData?: T) => void;
  children?: React.ReactNode;
  readonly?: boolean;
  addPlaceholders?: boolean;
}

const getUiSchemaWithPlaceholders = (
  schema: RJSFSchema,
  uiSchema: UiSchema
) => {
  const newUiSchema = { ...uiSchema };
  const properties = schema.properties || {};
  for (const key in properties) {
    if (properties[key].examples) {
      newUiSchema[key] = {
        ...newUiSchema[key],
        "ui:placeholder": properties[key].examples?.[0],
      };
    }
  }
  return newUiSchema;
};

function mergeWithDefaultToastOptions(
  options: Partial<ToastOptions>
): ToastOptions {
  return {
    loading: {
      ...defaultToastOptions.loading,
      ...options.loading,
    },
    success: {
      ...defaultToastOptions.success,
      ...options.success,
    },
    error: {
      ...defaultToastOptions.error,
      ...options.error,
    },
  };
}

export const toastPromise = (
  promise: Promise<unknown>,
  toast: CreateToastFnReturn,
  opts?: Partial<ToastOptions>
) => {
  toast.closeAll();
  return toast.promise(
    promise.catch((e) => {
      console.error(e);
      throw e;
    }),
    mergeWithDefaultToastOptions(opts || defaultToastOptions)
  );
};

interface OnSubmitArgs<T> {
  formData?: T | undefined;
  schema: RJSFSchema;
}

const JSONForm = <T,>({
  schema,
  uiSchema,
  onSubmit,
  toastOptions,
  initialData,
  widgets,
  onChange,
  readonly,
  FieldTemplate,
  children,
  addPlaceholders,
}: JSONFormProps<T>) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const toast = useToast();
  const [apiError, setApiError] = React.useState<string | null>(null);

  const handleSubmit = ({
    formData,
    schema: submittedSchema,
  }: OnSubmitArgs<T>) => {
    if (!formData) {
      throw new Error("formData is undefined");
    }
    setApiError(null);
    setIsLoading(true);
    return toastPromise(
      onSubmit(formData, submittedSchema)
        .catch((e) => {
          setApiError(getReadableApiError(e));
          console.error(e);
          throw e;
        })
        .finally(() => setIsLoading(false)),
      toast,
      toastOptions
    );
  };

  return (
    <Form
      readonly={readonly}
      disabled={isLoading}
      onSubmit={handleSubmit}
      schema={schema}
      uiSchema={
        addPlaceholders
          ? uiSchema
          : getUiSchemaWithPlaceholders(schema, uiSchema)
      }
      validator={validator}
      formData={initialData}
      widgets={widgets}
      onChange={
        onChange
          ? ({ formData }: OnSubmitArgs<T>, id: string | undefined) => {
              if (id) {
                onChange(formData);
              }
            }
          : undefined
      }
      templates={{ DescriptionFieldTemplate }}
    >
      {children}
      {apiError && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle>{apiError}</AlertTitle>
        </Alert>
      )}
      {!readonly && (
        <Box textAlign="end">
          <Button
            colorScheme="brand"
            type="submit"
            spinner={<Spinner />}
            isDisabled={isLoading}
          >
            Submit
          </Button>
        </Box>
      )}
    </Form>
  );
};

export default JSONForm;
