import { createContext, useContext, useState } from "react";
import { NodeType } from "typescript-axios";

const DnDContext = createContext<
  [NodeType | null, React.Dispatch<React.SetStateAction<NodeType | null>>]
>([null, (_) => {}]);

export const DnDProvider = ({ children }: { children: React.ReactNode }) => {
  const [type, setType] = useState<NodeType | null>(null);

  return (
    <DnDContext.Provider value={[type, setType]}>
      {children}
    </DnDContext.Provider>
  );
};

export default DnDContext;

export const useDnD = () => {
  return useContext(DnDContext);
};
