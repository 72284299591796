import React from "react";

interface NodeHovered {
  nodeHovered: string | null;
  setNodeHovered: (node: string | null) => void;
}
const NodeHoveredContext = React.createContext<NodeHovered>({
  nodeHovered: null,
  setNodeHovered: () => {},
});

export default NodeHoveredContext;
