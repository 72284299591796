import React, { useRef, useCallback, useState, useEffect } from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow,
  Background,
  Panel,
  useOnSelectionChange,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import { DnDProvider, useDnD } from "src/DnDContext";
import { FlowsApi, FlowRead } from "typescript-axios";
import "./index.css";
import Sidebar from "src/studio/sidebar";
import { inlineRefs } from "./lib/utils";

import {
  Box,
  Badge,
  Button,
  HStack,
  useDisclosure,
  Link,
  Text,
  Center,
  VStack,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  useToast,
  ListItem,
  UnorderedList,
  List,
  StackDivider,
  MenuItem,
  IconButton,
  Alert,
  AlertDescription,
  AlertIcon,
} from "@chakra-ui/react";
import JSONForm, { toastPromise } from "src/JSONForm";
import axios, { getAxiosParams } from "src/lib/axios.config";
import { useParams } from "react-router-dom";
import Dagre from "@dagrejs/dagre";
import Modal from "src/components/Modal";
import { getReadableApiError } from "src/lib/utils";
import { getUserFriendlyName } from "src/nodesFriendly";
import { CheckIcon } from "@chakra-ui/icons";
import { MdBugReport, MdGridView, MdHelp, MdRefresh } from "react-icons/md";
import { readNodeTypes, getOauthUrl } from "src/flowActions";
import { useTour, TourProvider } from "@reactour/tour";
import { createCustomNodes, getEdgesAndNodesFromJson } from "./appUtils";
import { StartButton, StopButton } from "./studio/StartButton";
import OAuthModalInfo from "./components/oauth";
import { TypeDesc } from "./components/node";
import NodeHoveredContext from "./node-hovered-context";

const FLOWS_API = new FlowsApi(getAxiosParams(), undefined, axios);

const TUTORIAL_STATE = {
  steps: [
    {
      selector: "#sidebar",
      content:
        "This is the sidebar, you can drag blocks from here to add them to your flow.",
    },
    {
      selector: "#first-group",
      content: "Blocks are grouped by category.",
    },
    {
      selector: "#first-node-info",
      content:
        "You can click this help icon to find out more about this type of node.",
    },
    {
      selector: "#layout-btn",
      content:
        "This is the layout button, you can click it to layout the blocks in the flow automatically in a clear way.",
    },
    {
      selector: "#validate-btn",
      content:
        "This is the validate button, you can click it to see if your flow is valid and ready to execute.",
    },
    {
      selector: "#test-btn",
      content:
        "This is the test button, you can click it to test your flow with dummy input data.",
    },
    {
      selector: "#start-btn",
      content: "This is the button to start or stop the flow.",
    },
  ],
};

const CustomFieldTemplate = (props) => {
  const { id, label, description, errors, children } = props;
  return (
    <div className="custom-field">
      {label && <label htmlFor={id}>{label}</label>}
      {description && <strong>description</strong>}
      {children}
      {errors}
    </div>
  );
};

const getLayoutedElements = (nodes, edges) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: "TB" });
  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) =>
    g.setNode(node.id, {
      ...node,
      width: node.measured?.width ?? 0,
      height: node.measured?.height ?? 0,
    })
  );

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const position = g.node(node.id);
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      const x = position.x - (node.measured?.width ?? 0) / 2;
      const y = position.y - (node.measured?.height ?? 0) / 2;

      return { ...node, position: { x, y } };
    }),
    edges,
  };
};

function combineSchemas(nodes, schemas) {
  let combinedSchema = {
    type: "object",
    properties: {},
    node_id: {},
  };

  const nodesMap = nodes.reduce((acc, node) => {
    acc[node.id] = node.data.name;
    return acc;
  }, {});

  Object.entries(schemas).forEach(([nodeId, schema]) => {
    const inlinedSchema = inlineRefs(schema);
    combinedSchema.properties[nodeId] = {
      type: "object",
      title: nodesMap[nodeId], // RJSF will group those
      properties: inlinedSchema.properties,
      required: inlinedSchema.required,
    };
  });

  return combinedSchema;
}

const HANDLE_STYLES = {
  target: {
    right: -10,
    // top: 15,
    background: "#d9f7fa",
    minWidth: 20,
    height: 20,
    border: "1px solid #76c7d1",
    borderRadius: 4,
    placeItems: "center",
    display: "grid",
    color: "#333",
    zIndex: 2,
  },
  source: {
    right: -10,
    // top: 43,
    background: "#ffebe6",
    minWidth: 20,
    height: 20,
    border: "1px solid #e0a800",
    borderRadius: 4,
    placeItems: "center",
    display: "grid",
    color: "#333",
    zIndex: 2,
  },
};

export interface FormData {
  [key: string]: unknown;
}

const persistChanges = (changes, flowId, setFlow) => {
  changes.forEach((change) => {
    if (change.type === "position" && !change.dragging) {
      FLOWS_API.updateNodeApiV1FlowsFlowIdNodesNodeIdPut(flowId, change.id, {
        kwargs: {
          pos_x: change.position.x,
          pos_y: change.position.y,
        },
      }).then((r) => setFlow(r.data));
    }
  });
};

function Flow() {
  const { setIsOpen } = useTour();
  const toast = useToast();
  let { flowId } = useParams();
  const [flow, setFlow] = React.useState<FlowRead>(null);
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [oauthUrl, setOauthUrl] = useState<string | null>(null);
  const [nodeTypes, setNodeTypes] = useState(null);
  const [customNodes, setCustomNodes] = useState(null);
  const [dropPosition, setDropPosition] = useState({ x: 0, y: 0 });
  const [exampleSchema, setExampleSchema] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [issues, setIssues] = useState(null);
  const [issuesButtonDisabled, setIssuesButtonDisabled] = useState(true);
  const { setNodeHovered } = React.useContext(NodeHoveredContext);
  const [selectedEdges, setSelectedEdges] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [edgeHovered, setEdgeHovered] = useState(null);

  const leaveTimeoutRef = React.useRef(null); // Store the timeout ID

  // the passed handler has to be memoized, otherwise the hook will not work correctly
  const onChange = useCallback(({ nodes, edges }) => {
    setSelectedEdges(edges.map((edge) => edge.id));
    setSelectedNodes(nodes.map((node) => node.id));
  }, []);

  useOnSelectionChange({
    onChange,
  });

  const {
    isOpen: isOpenAddNode,
    onOpen: onOpenAddNode,
    onClose: onCloseAddNode,
  } = useDisclosure();

  const {
    isOpen: isOpenIssues,
    onOpen: onOpenIssues,
    onClose: onCloseIssues,
  } = useDisclosure();

  const {
    isOpen: isOpenShowCommand,
    onOpen: onOpenShowCommand,
    onClose: onCloseShowCommand,
  } = useDisclosure();

  const {
    isOpen: isOpenTestFlow,
    onOpen: onOpenTestFlow,
    onClose: onCloseTestFlow,
  } = useDisclosure();

  const { screenToFlowPosition, fitView } = useReactFlow();
  const [type] = useDnD();

  const onLayout = useCallback(
    async (ns, es) => {
      if (ns.length === 0) {
        // do not remove this check, it will cause a crash
        return;
      }
      const layouted = getLayoutedElements(ns, es);
      setNodes([...layouted.nodes]);

      let flowUpdated = flow;
      for (const node of layouted.nodes) {
        const nodeUpdate = {
          kwargs: {
            pos_x: node.position.x,
            pos_y: node.position.y,
          },
        };
        flowUpdated = await FLOWS_API.updateNodeApiV1FlowsFlowIdNodesNodeIdPut(
          flowId,
          node.id,
          nodeUpdate
        ).then((r) => r.data);
      }
      setFlow(flowUpdated);

      window.requestAnimationFrame(() => {
        fitView();
      });
    },
    [flowId, setNodes, fitView, setFlow]
  );

  const fetchIssues = useCallback(() => {
    return toastPromise(
      FLOWS_API.readIssuesApiV1FlowsFlowIdIssuesGet(flowId)
        .then((response) => {
          setIssues(response.data);
          onOpenIssues();
        })
        .catch((e) => {
          console.error(e);
          throw e;
        }),
      toast,
      {
        success: { title: "Issues fetched" },
      }
    );
  }, [flowId, setIssues, onOpenIssues, toast]);

  const onValidate = () => {
    setIsLoading(true);
    return FLOWS_API.validateFlowApiV1FlowsFlowIdValidatePost(flowId)
      .then((response) => {
        toast.closeAll();
        if (response.data.is_valid) {
          toast({
            title: "Flow is valid",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Flow is invalid",
            description: (
              <UnorderedList>
                {response.data.issues.map((issue) => (
                  <ListItem key={issue}>{issue}</ListItem>
                ))}
              </UnorderedList>
            ),
            status: "error",
            duration: 100000,
            isClosable: true,
          });
        }
      })
      .catch((e) => {
        console.error(e);
        toast({
          title: "Error validating flow",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteNode = useCallback(async (nodeId) => {
    setIsLoading(true);
    return FLOWS_API.deleteNodeApiV1FlowsFlowIdNodesNodeIdDelete(flowId, nodeId)
      .then((r) => {
        setFlow(r.data);
        toast({
          isClosable: true,
          title: "Node deleted",
          status: "success",
        });
      })
      .catch((e) => {
        toast.closeAll();
        toast({
          title: "Error deleting node",
          description: getReadableApiError(e),
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onTest = () => {
    return getOauthUrl(
      nodes.map((n) => n.type),
      nodeTypes
    )
      .then((oauthUrl) => {
        setOauthUrl(oauthUrl);
        if (!oauthUrl) {
          setIsLoading(true);
          FLOWS_API.readExampleSchemaApiV1FlowsFlowIdExampleSchemaGet(flowId)
            .then((response) => {
              toast.closeAll();

              const combinedSchema = combineSchemas(nodes, response.data);
              const exampleUiSchema = {};
              if (exampleSchema) {
                Object.entries(combinedSchema.properties).forEach(
                  ([nodeId, nodeShema]) => {
                    exampleUiSchema[nodeId] = {};
                    Object.entries(nodeShema.properties).forEach(([k, v]) => {
                      if (v["hidden-for-example"]) {
                        exampleUiSchema[nodeId][k] = {
                          "ui:widget": "hidden",
                        };
                      }
                    });
                  }
                );
              }

              setExampleSchema({
                schema: combinedSchema,
                uiSchema: exampleUiSchema,
              });
              onOpenTestFlow();
            })
            .catch(() => {
              toast({
                title: "Error testing flow - did you run Validate first?",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        toast({
          title: "Error checking for scopes",
          description: getReadableApiError(e),
          status: "error",
        });
      });
  };
  useEffect(() => {
    readNodeTypes().then((schema) => {
      setNodeTypes(schema);
      setCustomNodes(createCustomNodes(schema, flowId, setFlow, deleteNode));
    });
  }, []);

  useEffect(() => {
    if (flow) {
      const [eds, nds] = getEdgesAndNodesFromJson(flow.flow_json);
      setNodes(nds);
      setEdges(eds);
    }
  }, [flow]);

  useEffect(() => {
    if (flowId) {
      FLOWS_API.readFlowApiV1FlowsFlowIdGet(flowId)
        .then((response) => {
          setFlow(response.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [flowId]);

  const onDelete = useCallback(({ edges, nodes }) => {
    const allDeletes = [];
    const deletedNodeIds = nodes.map((node) => node.id);
    deletedNodeIds.map((nodeId) =>
      allDeletes.push(
        FLOWS_API.deleteNodeApiV1FlowsFlowIdNodesNodeIdDelete(flowId, nodeId)
      )
    );
    edges.map(({ source, sourceHandle, target, targetHandle }) => {
      if (
        !deletedNodeIds.includes(source) &&
        !deletedNodeIds.includes(target)
      ) {
        // deleting nodes will take care of deleting edges
        allDeletes.push(
          FLOWS_API.disconnectNodesApiV1FlowsFlowIdDisconnectDelete(flowId, {
            src_node_id: source,
            src_handle: sourceHandle,
            tgt_node_id: target,
            tgt_handle: targetHandle,
          })
        );
      }
    });
    if (allDeletes.length === 0) {
      return false;
    }

    toastPromise(
      Promise.all(allDeletes).then(() => {
        FLOWS_API.readFlowApiV1FlowsFlowIdGet(flowId)
          .then((response) => {
            setFlow(response.data);
          })
          .catch((e) => {
            console.error(e);
            throw e;
          });
      }),
      toast,
      {
        success: { title: "Flow updated" },
      }
    );
    return false;
  }, []);

  const onConnect = useCallback(
    ({ source, sourceHandle, target, targetHandle }) => {
      FLOWS_API.connectNodesApiV1FlowsFlowIdConnectPost(flowId, {
        src_node_id: source,
        src_handle: sourceHandle,
        tgt_node_id: target,
        tgt_handle: targetHandle,
      })
        .then((r) => {
          toast.closeAll();
          toast({
            title: "Nodes connected",
            status: "success",
            isClosable: true,
          });
          setFlow(r.data);
        })
        .catch((e) => {
          toast({
            title: "Could not connect nodes",
            description: getReadableApiError(e),
            status: "error",
            isClosable: true,
          });
        });
    },
    []
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      setDropPosition(position);
      if (type) {
        onOpenAddNode();
      } else {
        onCloseAddNode();
      }
    },
    [screenToFlowPosition, type]
  );

  useEffect(() => {
    setNodes(
      nodes.map((n) => {
        if (selectedNodes.includes(n.id)) {
          return {
            ...n,
            style: {
              // border: "4px solid #007bff",
              // outline: "2px solid #007bff",
              boxShadow: "0 0 20px #007bffbb",
              outlineOffset: "4px",
              transition: "outline-offset 0.3s ease-in-out",
            },
          };
        } else {
          return {
            ...n,
            style: {},
          };
        }
      })
    );
  }, [selectedNodes]);

  useEffect(() => {
    const baseStyle = { strokeWidth: 2 };

    setEdges(
      edges.map((e) => {
        if (selectedEdges.includes(e.id)) {
          return {
            ...e,
            animated: false,
            style: { ...baseStyle, stroke: "#007bff", strokeWidth: 6 },
          };
        } else if (edgeHovered === e.id) {
          return {
            ...e,
            animated: true,
            style: { ...baseStyle, stroke: "#007bff", strokeWidth: 6 },
          };
        } else {
          return {
            ...e,
            animated: true,
            style: baseStyle,
          };
        }
      })
    );
  }, [selectedEdges, edgeHovered]);

  const onEdgeMouseEnter = (event, edge) => setEdgeHovered(edge.id);
  const onEdgeMouseLeave = (event, edge) => setEdgeHovered(null);

  const handleMouseEnter = (event, node) => {
    // Clear any scheduled "leave" event when the mouse re-enters
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
      leaveTimeoutRef.current = null;
    }

    // Set the node as hovered immediately
    setNodeHovered(node.id);
  };

  const handleMouseLeave = (event, node) => {
    // Set a delay of 2 seconds before un-hovering the node
    leaveTimeoutRef.current = setTimeout(() => {
      setNodeHovered(null);
      leaveTimeoutRef.current = null; // Reset the timeout ref after clearing
    }, 1000); // 2000 ms = 2 seconds
  };

  if (flow === null) {
    return (
      <Center minH="100%">
        <VStack>
          <Text>Loading flow...</Text>
          <Spinner />
        </VStack>
      </Center>
    );
  }

  return (
    <Box className="dndflow">
      <Modal
        header="Commands"
        isOpen={isOpenShowCommand}
        onClose={onCloseShowCommand}
      >
        <List spacing={2}>
          <ListItem>
            <Text>
              <b>Add a node:</b> drag a node from the sidebar to the canvas.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <b>Delete a node:</b> select a node and press the delete key.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <b>Connect nodes:</b> long click on the output handle of a node
              and drag it to the input handle of another node.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <b>Disconnect nodes:</b> click on the connection line between two
              nodes and press the delete key.
            </Text>
          </ListItem>
        </List>
      </Modal>
      <Modal
        isOpen={exampleSchema && isOpenTestFlow}
        onClose={onCloseTestFlow}
        header="Test Flow"
      >
        {exampleSchema ? (
          <>
            <Text>Click submit to test your flow.</Text>
            <JSONForm
              uiSchema={{
                "ui:options": { title: false },
                ...exampleSchema.uiSchema,
              }}
              schema={exampleSchema.schema}
              onSubmit={(formData) => {
                setIsLoading(true);
                return FLOWS_API.execExampleDataApiV1FlowsFlowIdExamplePost(
                  flowId,
                  formData
                )
                  .then((r) => {
                    setFlow(r.data);
                    onCloseTestFlow();
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            />
          </>
        ) : (
          <Spinner />
        )}
      </Modal>
      <Modal
        isOpen={isOpenAddNode}
        onClose={onCloseAddNode}
        header={`Add ${type?.kls ? getUserFriendlyName(type.kls) : "Node"}`}
      >
        {type ? (
          <VStack
            align="stretch"
            divider={<StackDivider borderWidth="2px" borderColor="gray.100" />}
          >
            <TypeDesc desc={type?.desc} />
            <JSONForm
              uiSchema={{
                "ui:options": { label: false },
                pos_x: { "ui:widget": "hidden" },
                pos_y: { "ui:widget": "hidden" },
              }}
              initialData={{
                pos_x: dropPosition.x,
                pos_y: dropPosition.y,
                name: getUserFriendlyName(type.kls),
              }}
              schema={type?.init}
              onSubmit={(formData) => {
                const data = {
                  kls: type.kls,
                  kwargs: formData,
                };
                return FLOWS_API.createNodeApiV1FlowsFlowIdNodesPost(
                  flowId,
                  data
                ).then((r) => {
                  setFlow(r.data);
                  onCloseAddNode();
                });
              }}
            >
              {type?.is_trigger && (
                <Alert mb={4} status="info" variant="left-accent">
                  <AlertIcon />
                  <AlertDescription>
                    Trigger block do not need to be connected to any other block
                    for the flow to run!
                  </AlertDescription>
                </Alert>
              )}
            </JSONForm>
          </VStack>
        ) : (
          <Spinner />
        )}
      </Modal>
      <OAuthModalInfo oauthUrl={oauthUrl} onClose={() => setOauthUrl(null)} />
      <Box className="reactflow-wrapper" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={customNodes || {}}
          onNodesChange={(changes) => {
            onNodesChange(changes);
            persistChanges(changes, flowId, setFlow);
          }}
          onNodeMouseEnter={handleMouseEnter}
          onNodeMouseLeave={handleMouseLeave}
          onEdgeMouseEnter={onEdgeMouseEnter}
          onEdgeMouseLeave={onEdgeMouseLeave}
          snapToGrid={true}
          snapGrid={[20, 20]}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onBeforeDelete={onDelete}
          onDrop={onDrop}
          onDragOver={onDragOver}
          fitView
        >
          <Controls />
          <Background />
          <Panel position="top-center">
            <VStack align="stretch">
              <HStack
                border="1px solid"
                borderColor="gray.300"
                p={2}
                bg="white"
                divider={<StackDivider />}
              >
                <Menu>
                  <MenuButton
                    py={2}
                    transition="all 0.3s"
                    _focus={{ boxShadow: "none" }}
                  >
                    <Button
                      id="help-btn"
                      isDisabled={isLoading}
                      size="sm"
                      variant="solid"
                      leftIcon={<MdHelp />}
                    >
                      Help
                    </Button>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => onOpenShowCommand((v) => !v)}>
                      Commands
                    </MenuItem>
                    <MenuItem onClick={() => setIsOpen((v) => !v)}>
                      Tutorial
                    </MenuItem>
                  </MenuList>
                </Menu>

                <Button
                  id="layout-btn"
                  isDisabled={isLoading}
                  size="sm"
                  onClick={() => onLayout(nodes, edges)}
                  variant="outline"
                  leftIcon={<MdGridView />}
                >
                  Layout
                </Button>
                <Button
                  id="validate-btn"
                  isDisabled={isLoading}
                  size="sm"
                  leftIcon={<CheckIcon />}
                  onClick={onValidate}
                  colorScheme="blue"
                  variant="outline"
                >
                  Validate
                </Button>
                <Button
                  id="test-btn"
                  isDisabled={isLoading}
                  size="sm"
                  leftIcon={<MdBugReport />}
                  onClick={onTest}
                  colorScheme="teal"
                  variant="outline"
                >
                  Test
                </Button>
                {flow.is_running && (
                  <StopButton flowId={flowId} onSuccess={setFlow} />
                )}
                {!flow.is_running && (
                  <StartButton
                    nodeTypes={nodeTypes}
                    id="start-btn"
                    size="sm"
                    flow={flow}
                    onSuccess={(f) => {
                      setFlow(f);
                      setIssuesButtonDisabled(true);
                      // We disable the button for 10 seconds to give time for the flow to start
                      setTimeout(() => {
                        setIssuesButtonDisabled(false);
                      }, 10000);
                    }}
                  />
                )}
                <VStack spacing={0}>
                  <Badge colorScheme={flow.is_running ? "green" : "gray"}>
                    {flow.is_running ? "Running" : "Inactive"}
                  </Badge>
                  {flow.is_running && (
                    <>
                      <Modal
                        isOpen={isOpenIssues}
                        onClose={onCloseIssues}
                        size="xl"
                        header={
                          <HStack>
                            <Text>Flow Issues</Text>
                            <IconButton
                              icon={<MdRefresh />}
                              onClick={fetchIssues}
                            />
                          </HStack>
                        }
                      >
                        {!issues && <Spinner />}
                        {issues && issues.length > 0 && (
                          <VStack>
                            {issues.map((log, ix) => (
                              <Alert
                                key={ix}
                                status="error"
                                variant="left-accent"
                              >
                                {log}
                              </Alert>
                            ))}
                          </VStack>
                        )}
                        {issues && issues.length == 0 && (
                          <Text>No issue has been reported!</Text>
                        )}
                        <Text mt={4}>
                          If you have a problem with this flow, reach out to
                          support on our{" "}
                          <Button
                            as={Link}
                            colorScheme="brand"
                            variant="link"
                            href="https://discord.gg/hTy7t42m"
                            isExternal
                          >
                            Discord Server
                          </Button>{" "}
                          ery (we answer quickly!)
                        </Text>
                      </Modal>
                      <Button
                        size="xs"
                        variant="link"
                        onClick={fetchIssues}
                        isDisabled={issuesButtonDisabled}
                      >
                        See issues
                      </Button>
                    </>
                  )}
                </VStack>
              </HStack>
            </VStack>
          </Panel>
        </ReactFlow>
      </Box>
      <Sidebar nodeTypes={nodeTypes} />
    </Box>
  );
}

const App = () => {
  const [nodeHovered, setNodeHovered] = useState<string | null>(null);

  return (
    <Box h="full">
      <TourProvider steps={TUTORIAL_STATE.steps}>
        <ReactFlowProvider>
          <DnDProvider>
            <NodeHoveredContext.Provider
              value={{ nodeHovered, setNodeHovered }}
            >
              <Flow />
            </NodeHoveredContext.Provider>
          </DnDProvider>
        </ReactFlowProvider>
      </TourProvider>
    </Box>
  );
};
export default App;
