import {
  useToast,
  VStack,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
  HStack,
  Button,
  useDisclosure,
  Text,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { MdChevronLeft } from "react-icons/md";
import { useParams, useNavigate } from "react-router-dom";
import { toastPromise } from "src/JSONForm";
import { InstaDMEscalatation, InstagramDm, MetaApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";
import Chat, { Message } from "./chat";
import Modal from "./Modal";
const META_API = new MetaApi(getAxiosParams(), undefined, axios);

const InstaDMEscalatationPage = ({}) => {
  const { escalationId } = useParams();
  const navigate = useNavigate();
  const [escalation, setEscalation] =
    React.useState<InstaDMEscalatation | null>(null);
  const [messages, setMessages] = React.useState<Message[]>([]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  React.useEffect(() => {
    if (escalationId) {
      META_API.readEscalationApiV1MetaInstagramNewDmsEscalationEscalationIdGet(
        escalationId
      ).then((res) => {
        setEscalation(res.data);
      });
    }
  }, [escalationId]);

  React.useEffect(() => {
    if (escalation) {
      META_API.getInstagramConversationApiV1MetaInstagramConversationGet(
        escalation.recipient_id,
        escalation.sender_id,
        50
      ).then((res) => {
        setMessages(
          res.data.map((m: InstagramDm) => ({
            user_id: m.from_user.id,
            username: m.from_user.username,
            message: m.message,
            timestamp: m.timestamp,
          }))
        );
      });
    }
  }, [escalation]);

  const sendMessage = (message: string) => {
    if (!escalation) {
      return Promise.resolve();
    }
    return META_API.sendDmApiV1MetaInstagramConversationPost({
      recipient_id: escalation.sender_id,
      sender_id: escalation.recipient_id,
      message,
    }).then((res) => {
      setMessages([
        ...messages,
        {
          user_id: res.data.from_user.id,
          username: res.data.from_user.username,
          message: res.data.message,
          timestamp: res.data.timestamp,
        },
      ]);
    });
  };

  const Header = React.useMemo(
    () => (
      <Box py={2}>
        <Button
          variant="link"
          leftIcon={<MdChevronLeft />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <HStack justify="space-between">
          <Heading size="md">
            Instagram DM Escalation with {escalation?.sender_username}
          </Heading>
          <Button onClick={onOpen}>Resolve</Button>
        </HStack>
      </Box>
    ),
    [escalation]
  );

  const resolveEscalation = () => {
    if (!escalationId) {
      return;
    }
    toastPromise(
      META_API.resolveEscalationApiV1MetaInstagramNewDmsResolvePost(
        escalationId
      ).then(() => {
        navigate("/account", { replace: true });
      }),
      toast,
      {
        success: { title: "Escalation resolved" },
        error: { title: "Failed to resolve escalation" },
      }
    );
  };

  if (!escalationId) {
    return <div>Invalid escalation ID</div>;
  }

  if (!escalation) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='lg'>
        <VStack align="stretch">
          <Heading size="md">
            Instagram DM Escalation with {escalation.sender_username}
          </Heading>
          <Alert status="info" variant="left-accent">
            <AlertIcon />
            <AlertDescription fontWeight="bold">
              After resolving this escalation, new messages from {escalation.sender_username} will
              trigger your flow again.
            </AlertDescription>
          </Alert>
          <Text>Do you want to resolve this escalation?</Text>
          <HStack justify="end">
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={() => {
                if (escalation) {
                  resolveEscalation(escalation.id);
                }
              }}
              colorScheme="brand"
            >
              Resolve
            </Button>
          </HStack>
        </VStack>
      </Modal>
      <Box>
        <Chat
          userId={escalation.recipient_id}
          header={Header}
          messages={messages}
          sendMessage={sendMessage}
        />
      </Box>
    </>
  );
};

export default InstaDMEscalatationPage;
