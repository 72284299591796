import { Edge, Node, useConnection } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import NodeJSX from "src/components/node";
import { NodeType, FlowRead, FlowsApi } from "typescript-axios";
import "./index.css";

import axios, { getAxiosParams } from "src/lib/axios.config";
import { inlineRefs } from "./lib/utils";
const FLOWS_API = new FlowsApi(getAxiosParams(), undefined, axios);

export const createCustomNodes = (
  nodeTypes: NodeType[],
  flowId: string | null = null,
  setFlow: ((flow: FlowRead) => void) | null = null,
  deleteNode: ((nodeId: string) => Promise<void>) | null = null,
  isDemo = false
) => {
  if (!isDemo) {
    if (!flowId) {
      throw Error("Flow ID is required");
    }
    if (!setFlow) {
      throw Error("setFlow function is required");
    }
    if (!deleteNode) {
      throw Error("deleteNode function is required");
    }
  }

  const customNodes = {};
  nodeTypes.forEach((nodeType) => {
    customNodes[nodeType.kls] = ({ data }) => {
      const { id: nodeId, ...rest } = data;

      const showRun =
        !nodeType.is_trigger &&
        !nodeType.kls.toLowerCase().includes("format") &&
        !["Text", "Switch"].includes(nodeType.kls);

      const initSchema = nodeType.init;
      const outputSchema = data.output_schema
        ? inlineRefs(data.output_schema)
        : nodeType.outputs;
      const inputSchema = data.input_schema
        ? inlineRefs(data.input_schema)
        : nodeType.inputs;

      if (!showRun && inputSchema.properties) {
        delete inputSchema.properties.run;
      }

      const editNodeWithId = (kwargs) => {
        return FLOWS_API.updateNodeApiV1FlowsFlowIdNodesNodeIdPut(
          flowId,
          nodeId,
          {
            kwargs,
          }
        ).then((r) => {
          setFlow(r.data);
        });
      };

      const deleteNodeWithId = deleteNode ? () => deleteNode(nodeId) : null;

      return (
        <NodeJSX
          nodeId={nodeId}
          nodeType={nodeType}
          initSchema={initSchema}
          inputSchema={inputSchema}
          outputSchema={outputSchema}
          deleteNode={deleteNodeWithId}
          editNode={editNodeWithId}
          data={rest}
          isDemo={isDemo}
        />
      );
    };
  });
  return customNodes;
};

export const getEdgesAndNodesFromJson = (json: object): [Edge[], Node[]] => {
  const edges: Edge[] = [];
  const runsConnected = {};
  json.links?.forEach(({ src_handle, tgt_handle, source, target }) => {
    const isRun = tgt_handle === "run";
    if (isRun) {
      runsConnected[target] = true;
    }

    edges.push({
      source,
      target,
      id: `${source}_${src_handle}_${target}_${tgt_handle}`,
      sourceHandle: src_handle,
      targetHandle: tgt_handle,
      animated: true,
      type: "smoothstep",
      style: { strokeWidth: 2 },
    });
  });
  const nodes: Node[] = [];
  json.nodes.forEach(({ id: node }, ix) => {
    const data = Object.entries(node).reduce((acc, [key, val]) => {
      if (
        {
          pos_x: true,
          pos_y: true,
          _node_id: true,
          kls: true,
        }[key]
      ) {
        return acc;
      }
      acc[key] = val;
      return acc;
    }, {});
    const frontEndNode = {
      id: node._node_id,
      type: node.kls,
      data: {
        ...data,
        id: node._node_id,
        isRunConnected: runsConnected[node._node_id],
        pos_x: node.pos_x,
        pos_y: node.pos_y,
      },
      position: { x: node.pos_x, y: node.pos_y }, // required
    };

    nodes.push(frontEndNode);
  });
  return [edges, nodes];
};
