import cookie from "cookie";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { VStack, Text, Spinner } from "@chakra-ui/react";

const Logout = () => {
  const [searchParams] = useSearchParams();
  React.useEffect(() => {
    document.cookie = cookie.serialize("token", "", {
      path: "/",
      maxAge: 0,
    });
    const next = searchParams.get("next");
    const auth = searchParams.get("auth") !== "0";

    let href = null;
    if (auth) {
      href = "/auth";
      if (next) {
        href += `?next=${next}`;
      }
    } else {
      href = next || "/auth"; // FIXME: what is the page that is accessible without auth?
    }
    window.location.href = href;
  }, []);

  return (
    <VStack spacing={4} align={"center"}>
      <Text fontSize={"lg"} color={"gray.600"}>
        Logging out...
      </Text>
      <Spinner />
    </VStack>
  );
};

export default Logout;
