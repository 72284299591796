import { Box, Center, Spinner } from "@chakra-ui/react";

import SCHEMAS from "src/res/demo-node-types.json";
import { useState } from "react";
import { ReactFlow, Controls, Background } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { createCustomNodes } from "src/appUtils";

import React from "react";

const initialNodes = [
  {
    id: "inbox",
    type: "NewEmail",
    data: { name: "New email received" },
    position: { x: 0, y: 0 },
  },
  {
    id: "outbox",
    type: "SendEmailSMTP",
    data: { name: "Send email" },
    position: { x: 0, y: 320 },
  },
  {
    id: "gpt",
    type: "Instruct",
    data: {
      name: "Redact answer",
      output_schema: {
        properties: {
          subject: {
            type: "string",
            title: "Subject",
          },
          body: {
            type: "string",
            title: "Body",
          },
        },
      },
    },
    position: { x: 100, y: 200 },
  },
  {
    id: "db",
    type: "Database",
    data: {
      name: "Knowledge",
      output_schema: {
        properties: {
          knowledge: {
            type: "string",
            title: "Knowledge",
          },
        },
      },
    },
    position: { x: 250, y: 0 },
  },
];

const initialEdges = [
  {
    id: "inbox-gpt-sender_name",
    source: "inbox",
    target: "gpt",
    // label: "Sender's address",
    sourceHandle: "sender_name",
    targetHandle: "contexts",
    animated: true,
  },
  {
    id: "inbox-outbox-email",
    source: "inbox",
    target: "outbox",
    // label: "Sender's address",
    sourceHandle: "sender_email",
    targetHandle: "email_address",
    animated: true,
  },
  {
    id: "db-gpt-context",
    source: "db",
    target: "gpt",
    // label: "Knowledge",
    sourceHandle: "knowledge",
    targetHandle: "contexts",
    animated: true,
  },
  {
    id: "inbox-gpt-subject-subject",
    source: "inbox",
    target: "gpt",
    // label: "Email subject",
    sourceHandle: "subject",
    targetHandle: "contexts",
    animated: true,
  },
  {
    id: "inbox-gpt-body-context",
    source: "inbox",
    target: "gpt",
    // label: "Email body",
    sourceHandle: "body",
    targetHandle: "contexts",
    animated: true,
  },
  {
    id: "gpt-outbox-subject",
    source: "gpt",
    target: "outbox",
    // label: "Subject",
    sourceHandle: "subject",
    targetHandle: "subject",
    animated: true,
  },
  {
    id: "gpt-outbox-body",
    source: "gpt",
    target: "outbox",
    // label: "Body",
    sourceHandle: "body",
    targetHandle: "body",
    animated: true,
  },
];

const Demo = () => {
  const [customNodes, setCustomNodes] = useState(null);

  const [nodes] = useState(initialNodes);
  const [edges] = useState(initialEdges);

  React.useEffect(() => {
    if (SCHEMAS) {
      const customNodes = createCustomNodes(SCHEMAS, null, null, null, true);
      setCustomNodes(customNodes);
    }
  }, [SCHEMAS]);

  return (
    <Box h="100%">
      {customNodes ? (
        <ReactFlow
          onNodesChange={() => null}
          onEdgesChange={() => null}
          onConnect={() => null}
          edgesFocusable={false}
          nodesDraggable={false}
          nodesConnectable={false}
          nodesFocusable={true}
          elementsSelectable={true}
          zoomOnScroll={false}
          panOnScroll={false}
          preventScrolling={false}
          nodeTypes={customNodes || {}}
          nodes={nodes}
          edges={edges}
          fitView
          maxZoom={1}
          minZoom={1}
        >
          <Background />
          {/* <Controls /> */}
        </ReactFlow>
      ) : (
        <Center>
          <Spinner />
        </Center>
      )}
    </Box>
  );
};

export default Demo;
