"use client";

import {
  Box,
  Flex,
  Stack,
  useColorModeValue,
  Card,
  CardHeader,
  CardBody,
  Heading,
} from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Demo from "src/components/demo";

const AuthLayout = () => {
  return (
    <>
      <head>
        <title>Build Any AI Flow - No Code | Plurally</title>
      </head>
      <Box>
        <main>
          <Flex minH="100vh" align={"center"} justify={"center"}>
            <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
              <Card
                rounded={"lg"}
                bg={useColorModeValue("white", "gray.700")}
                boxShadow={"lg"}
                p={8}
              >
                <CardHeader>
                  <Heading size="md">Plurally</Heading>
                </CardHeader>
                <CardBody>
                  <Outlet />
                </CardBody>
              </Card>
            </Stack>
          </Flex>
        </main>
      </Box>
    </>
  );
};

export default AuthLayout;
