import {
  Link,
  VStack,
  Card,
  CardHeader,
  Heading,
  CardBody,
  StackDivider,
  Table,
  Button,
  Box,
  HStack,
  Spinner,
  Badge,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  useToast,
  LinkOverlay,
  Flex,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import {
  SubscriptionsApi,
  UsersApi,
  AuthApi,
  MetaApi,
  SubscriptionRead,
  UserRead,
  TokenRead,
  InstaDMEscalatation,
} from "typescript-axios";
import axios, { getAxiosParams } from "./lib/axios.config";
import { MdDelete } from "react-icons/md";
import Modal from "./components/Modal";
import { toastPromise } from "./JSONForm";

const META_API = new MetaApi(getAxiosParams(), undefined, axios);
const SUBS_API = new SubscriptionsApi(getAxiosParams(), undefined, axios);
const USERS_API = new UsersApi(getAxiosParams(), undefined, axios);
const AUTH_API = new AuthApi(getAxiosParams(), undefined, axios);

const getSubscriptionPlanName = (plan: string) => {
  if (!plan) return "Free";
  if (plan.startsWith("individual")) return "Individual";
  if (plan.startsWith("pro")) return "Pro";
  return plan;
};

const OAuth = () => {
  const toast = useToast();
  const [tokens, setTokens] = React.useState<TokenRead[] | null>(null);
  const [tokenForDelete, setTokenForDelete] = React.useState<TokenRead | null>(
    null
  );

  React.useEffect(() => {
    AUTH_API.readTokensApiV1AuthMeGet().then((res) => {
      setTokens(res.data);
    });
  }, []);

  if (!tokens) {
    return <Spinner />;
  }

  return (
    <VStack align="stretch">
      <Modal isOpen={tokenForDelete} onClose={() => setTokenForDelete(null)}>
        <VStack align="stretch">
          <Heading size="md">Danger</Heading>
          <Text>You are about to revoke this permission.</Text>
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <AlertDescription fontWeight="bold">
              All your flows using this will stop working.
            </AlertDescription>
          </Alert>
          <HStack justify="end">
            <Button onClick={() => setTokenForDelete(null)}>Cancel</Button>
            <Button
              variant="ghost"
              onClick={() => {
                if (tokenForDelete?.id) {
                  toastPromise(
                    AUTH_API.deleteTokenApiV1AuthTokenTokenIdDelete(
                      tokenForDelete?.id
                    ).then(() => {
                      setTokens(
                        tokens.filter((t) => t.id !== tokenForDelete?.id)
                      );
                      setTokenForDelete(null);
                    }),
                    toast,
                    {
                      success: { title: "Permission revoked" },
                      error: { title: "Failed to revoke permission" },
                    }
                  );
                }
              }}
              colorScheme="red"
            >
              Revoke
            </Button>
          </HStack>
        </VStack>
      </Modal>
      <VStack align="stretch">
        <Box>
          <Heading size="sm">
            <Text>Permissions</Text>
          </Heading>
          {tokens.length > 0 ? (
            <Text>You have granted permissions to</Text>
          ) : (
            <Text>You have not granted any permission yet.</Text>
          )}
        </Box>
      </VStack>
      <Box fontSize="sm" p={4}>
        <VStack align="stretch">
          <VStack align="stretch" divider={<StackDivider />}>
            {tokens.map((token) => (
              <Box key={`${token.id}`}>
                <Heading size="sm">{token.provider}</Heading>
                <Button
                  leftIcon={<MdDelete />}
                  variant="link"
                  colorScheme="red"
                  onClick={() => setTokenForDelete(token)}
                  size="xs"
                >
                  Revoke
                </Button>
                <Text>{token.scopes}</Text>
              </Box>
            ))}
          </VStack>
        </VStack>
      </Box>
    </VStack>
  );
};

const InstagramDmEscalations = () => {
  const [escalations, setEscalations] = React.useState<
    InstaDMEscalatation[] | null
  >(null);

  React.useEffect(() => {
    META_API.readEscalationsApiV1MetaInstagramNewDmsEscalationMeGet().then(
      (res) => {
        console.debug(res.data);
        setEscalations(res.data);
      }
    );
  }, []);

  if (!escalations || escalations.length === 0) {
    return "";
  }

  return (
    <Card>
      <CardHeader>
        <Heading size="md">Instagram DM Escalations</Heading>
      </CardHeader>
      <CardBody>
        <VStack align="stretch">
          <VStack align="stretch">
            <Text>
              Escalations happen when a user asked to talk to you directly. When
              this happens, new messages from this user will not trigger your
              flow anymore.
            </Text>
          </VStack>
          <Box fontSize="sm" p={4}>
            <VStack align="stretch">
              <SimpleGrid spacing={4} p={4} columns={{ base: 1, lg: 2 }}>
                {escalations.map((escalation) => (
                  <Flex
                    p={4}
                    border="1px solid #E2E8F0"
                    justifyContent={"space-between"}
                    key={`${escalation.id}`}
                  >
                    <Box key={`${escalation.id}`}>
                      <Heading size="sm">{escalation.sender_username}</Heading>{" "}
                      has required to talk to you directly.
                    </Box>
                    <Button as={Link} href={`/escalations/${escalation.id}`}>
                      See case
                    </Button>
                  </Flex>
                ))}
              </SimpleGrid>
            </VStack>
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
};

const Account = () => {
  const [subs, setSubs] = React.useState<SubscriptionRead[] | null>(null);
  const [user, setUser] = React.useState<UserRead | null>(null);

  React.useEffect(() => {
    SUBS_API.readSubscriptionsApiV1SubscriptionsMeGet().then((res) => {
      setSubs(res.data);
    });
    USERS_API.readMeApiV1UsersMeGet().then((res) => {
      setUser(res.data);
    });
  }, []);

  return (
    <>
      <SimpleGrid p={4} spacing={4} columns={{ base: 1 }}>
        <Card>
          <CardHeader>
            <Heading size="md">Billing</Heading>
          </CardHeader>
          <CardBody>
            <VStack align="stretch" divider={<StackDivider />}>
              <VStack align="stretch">
                <Box>
                  <Heading size="sm">
                    <Text>Subscriptions</Text>
                  </Heading>
                  <HStack>
                    <Text fontSize="xs">Current plan: </Text>
                    {user ? (
                      <Badge
                        colorScheme={
                          user.current_plan === null ? "gray" : "brand"
                        }
                      >
                        {getSubscriptionPlanName(user.current_plan)}
                      </Badge>
                    ) : (
                      <Spinner />
                    )}
                  </HStack>
                </Box>
              </VStack>
              <Box fontSize="sm">
                <Text>
                  You can consult your billing information and manage your
                  current subscription{" "}
                  <Button
                    variant="link"
                    colorScheme="brand"
                    isExternal
                    as={Link}
                    href={import.meta.env.VITE_STRIPE_CUSTOMER_PORTAL}
                  >
                    here
                  </Button>
                  .
                </Text>
                <Text>
                  For any questions or for refund, reach out to us at{" "}
                  <Button
                    variant="link"
                    colorScheme="brand"
                    isExternal
                    as={Link}
                    href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
                  >
                    {import.meta.env.VITE_SUPPORT_EMAIL}
                  </Button>
                </Text>
              </Box>
            </VStack>
          </CardBody>
        </Card>
        <InstagramDmEscalations id="insta-dm-escalations" />
        <Card>
          <CardHeader>
            <Heading size="md">Security</Heading>
          </CardHeader>
          <CardBody>
            <OAuth />
          </CardBody>
        </Card>
      </SimpleGrid>
    </>
  );
};

export default Account;
