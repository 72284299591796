import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  VStack,
  Text,
  useColorModeValue,
  Textarea,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useState, useRef, useEffect } from "react";
import { MdSend } from "react-icons/md";

export interface Message {
  user_id: string;
  username: string;
  message: string;
  timestamp: string;
}
interface ChatProps {
  header: React.ReactNode;
  messages: Message[];
  userId: string;
  sendMessage: (message: string) => Promise<void>;
}

const Chat = ({ header, messages, userId, sendMessage }: ChatProps) => {
  const [newMessage, setNewMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const messagesEndRef = useRef(null);
  const bgMe = useColorModeValue("brand.100", "gray.200");

  // Scroll to the bottom of messages when a new message is added
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Flex
      w="100%"
      direction="column"
      h="calc(100vh - 80px)"
      maxHeight="calc(100vh - 80px)"
      p={8}
    >
      {header}
      <VStack
        flex="1"
        spacing={4}
        overflowY="auto"
        align="start"
        padding={4}
        bg="gray.100"
      >
        {messages.map((message) => (
          <Flex
            w="full"
            key={message.timestamp}
            direction={message.user_id === userId ? "row-reverse" : "row"}
            pe={message.user_id === userId ? 0 : "10%"}
            ps={message.user_id === userId ? "10%" : 0}
          >
            <Flex
              direction="column"
              alignItems={message.user_id === userId ? "end" : "start"}
            >
              <Heading size="sm">{message.username}</Heading>
              <Flex
                p={2}
                alignItems="right"
                w="fit-content"
                borderRadius="md"
                backgroundColor={message.user_id === userId ? bgMe : "teal.100"}
              >
                <Text>{message.message}</Text>
              </Flex>
              <Text fontSize="xs">
                {dayjs.utc(message.timestamp).local().format("LLL")}
              </Text>
            </Flex>
          </Flex>
        ))}
        <div ref={messagesEndRef} />
      </VStack>

      {/* Input and button */}
      <Flex
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          setIsLoading(true);
          sendMessage(newMessage)
            .then(() => {
              setNewMessage("");
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        padding={4}
        backgroundColor="white"
        boxShadow="md"
        alignItems="center"
      >
        <Textarea
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          flex="1"
          mr={2}
        />
        <Button
          isLoading={isLoading}
          colorScheme="brand"
          type="submit"
          leftIcon={<MdSend />}
        >
          Send
        </Button>
      </Flex>
    </Flex>
  );
};

export default Chat;
