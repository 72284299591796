import {
  Flex,
  VStack,
  Heading,
  Box,
  Badge,
  HStack,
  Button,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { CiMenuKebab } from "react-icons/ci";
import { Link } from "react-router-dom";

const Action = ({ to, title, variant, colorScheme, onClick, component }) => {
  if (component) {
    return component;
  } else if (to) {
    return (
      <Link to={to}>
        <Button variant={variant} colorScheme={colorScheme}>
          {title}
        </Button>
      </Link>
    );
  } else {
    return (
      <Button onClick={onClick} variant={variant} colorScheme={colorScheme}>
        {title}
      </Button>
    );
  }
};

const RowWithActions = ({ children, plusActions, actions }) => {
  return (
    <Flex justify="space-between">
      {children}
      <HStack>
        {actions?.map((action) => (
          <Action {...action} key={action.title} />
        ))}
        {Object.keys(plusActions || [])?.length !== 0 && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<CiMenuKebab />}
              variant="outline"
            />
            <MenuList>
              {Object.entries(plusActions)?.map(([title, action]) => (
                <MenuItem onClick={action} key={title}>
                  {title}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </HStack>
    </Flex>
  );
};

export default RowWithActions;
