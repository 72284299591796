import type { DeepPartial, Theme } from '@chakra-ui/react';

const brand100 = '#cbb2ff';
/** extend additional color here */
const extendedColors: DeepPartial<
  Record<string, Theme['colors']['blackAlpha']>
> = {
  brand: {
    50: '#f0e4ff',
    100: brand100,
    200: '#a480ff',
    300: '#7a4dff',
    400: '#7434fe',
    500: '#5a01e5',
    600: '#5200b3',
    700: '#430081',
    800: '#2d004f',
    900: '#14001f',
  },
  brandSecondary: {
    50: '#51ff7f',
    100: '#4bff77',
    200: '#46ff6e',
    300: '#40ff66',
    400: '#3bff5d', // Closest to new midpoint
    500: '#30d84c',
    600: '#2bc044',
    700: '#25a83b',
    800: '#209033',
    900: '#1b782a',
  },
};

/** override chakra colors here */
const overridenChakraColors: DeepPartial<Theme['colors']> = {};

export const colors = {
  ...overridenChakraColors,
  ...extendedColors,
  hypertext: brand100,
};
