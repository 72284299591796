"use client";

import { Box, BoxProps, FlexProps } from "@chakra-ui/react";
import { FiHome, FiCompass } from "react-icons/fi";
import { IconType } from "react-icons";
import MobileNav from "src/components/mobile-nav";
import { Outlet } from "react-router-dom";

interface LinkItemProps {
  name: string;
  icon: IconType;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const LINKS: Array<LinkItemProps> = [
  { name: "Dashboard", icon: FiHome, href: "/studio" },
  { name: "Explore", icon: FiCompass, href: "/explore" },
];

const Layout = ({ children }) => {
  const navbarHeight = "80px";
  return (
    <>
      <head>
        <title>Studio | Plurally</title>
      </head>
      <div>
        <header>
          <MobileNav links={LINKS} height={navbarHeight} />
        </header>
        <main>
          <Box
            h={`calc(100vh - ${navbarHeight})`}
            w="100%"
            display="flex"
            flexDirection="column"
          >
            <Outlet />
          </Box>
        </main>
      </div>
    </>
  );
};

export default Layout;
