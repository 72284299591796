import {
  EnvVariableRead,
  FlowCreate,
  FlowRead,
  FlowsApi,
  UsersApi,
  EnvVariableCreate,
  TemplatesApi,
  TemplateRead,
} from "typescript-axios";
import dayjs from "src/lib/dayjs.config";
import axios, { getAxiosParams } from "src/lib/axios.config";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Spinner,
  useDisclosure,
  VStack,
  SimpleGrid,
  Text,
  StackDivider,
  Box,
  Badge,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { getReadableApiError, redirectIfNotAuthorized } from "src/lib/utils";
import JSONForm, { toastPromise } from "src/JSONForm";
import jsonSchemas from "src/gen/json-schema.json";

import RowWithActions from "src/components/RowWithActions";
import Modal from "src/components/Modal";
import { StartButton, StopButton } from "./studio/StartButton";
import { useNavigate } from "react-router-dom";
import { readNodeTypes } from "./flowActions";
import SubscribeModal, { canCreateNewFlow } from "./studio/SubscribeModal";
import { MdAdd } from "react-icons/md";

const FLOWS_API = new FlowsApi(getAxiosParams(), undefined, axios);
const USERS_API = new UsersApi(getAxiosParams(), undefined, axios);
const TEMPLATES_API = new TemplatesApi(getAxiosParams(), undefined, axios);

const CREATE_FLOW_SCHEMA = jsonSchemas.components.schemas["FlowCreate"];
const CREATE_ENV_VAR_SCHEMA =
  jsonSchemas.components.schemas["EnvVariableCreate"];

const Flows = ({ flows, onUpdate, onDelete, onPublish }) => {
  const [nodeTypes, setNodeTypes] = React.useState<NodeType[] | null>(null);

  React.useEffect(() => {
    readNodeTypes().then(setNodeTypes);
  }, []);

  if (flows === null) {
    return (
      <Box>
        <Spinner />
      </Box>
    );
  }
  if (flows.length === 0) {
    return (
      <Text mb={8}>
        You have not created a flow yet.
        <br />
        Click the button below to create your first flow.
      </Text>
    );
  }
  return (
    <VStack
      mb={8}
      spacing={4}
      align="stretch"
      divider={<StackDivider borderColor="gray.200" />}
    >
      {flows.map((f) => (
        <RowWithActions
          key={f.id}
          plusActions={{
            Publish: () => onPublish(f.id),
            Delete: () => onDelete(f.id),
          }}
          actions={[
            {
              title: "Edit",
              to: `/studio/${f.id}`,
              variant: "outline",
              // colorScheme: "brand",
            },
            {
              title: "Start",
              component: f.is_running ? (
                <StopButton onSuccess={onUpdate} flowId={f.id} />
              ) : (
                <StartButton
                  onSuccess={onUpdate}
                  flow={f}
                  nodeTypes={nodeTypes}
                />
              ),
            },
          ]}
        >
          <VStack align="stretch">
            <Heading size="md">{f.name}</Heading>
            <Box>
              {f.is_running ? (
                <Badge colorScheme="green">Running</Badge>
              ) : (
                <Badge colorScheme="gray">Inactive</Badge>
              )}
            </Box>
            <Text fontSize="xs">
              Last updated: {dayjs.utc(f.last_updated).local().format("LLL")}
            </Text>
            <Text fontSize="xs">
              Last executed: {f.last_exec_dt && dayjs.utc(f.last_exec_dt).local().format("LLL")}
            </Text>
            <Text fontSize="xs">Id: {f.id.substr(0, 4)}</Text>
          </VStack>
        </RowWithActions>
      ))}
    </VStack>
  );
};
const Home = () => {
  const [oauthUrl, setOauthUrl] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const [envVariables, setEnvVariables] = React.useState<
    EnvVariableRead[] | null
  >(null);
  const [templates, setTemplates] = React.useState<TemplateRead[] | null>(null);

  React.useEffect(() => {
    USERS_API.readEnvVarsApiV1UsersMeEnvVarsGet().then((res) => {
      setEnvVariables(res.data);
    });
  }, []);

  React.useEffect(() => {
    TEMPLATES_API.readMyTemplatesApiV1TemplatesMeGet().then((res) => {
      setTemplates(res.data);
    });
  }, []);

  const deleteEnvVars = useCallback((envVarId: string) => {
    toastPromise(
      USERS_API.deleteEnvVarApiV1UsersMeEnvVarsEnvVarIdDelete(envVarId)
        .then(() => {
          setEnvVariables((keys) => keys?.filter((k) => k.id !== envVarId));
        })
        .catch((e) => {
          console.error(e);
          throw e;
        }),
      toast
    );
  }, []);

  const deleteTemplate = useCallback((templateId: string) => {
    toastPromise(
      TEMPLATES_API.deleteTemplateApiV1TemplatesTemplateIdDelete(
        templateId
      ).then(() => {
        setTemplates((keys) => keys?.filter((k) => k.id !== templateId));
      }),
      toast
    );
  }, []);

  const EnvVars = useCallback(({ envVariables }) => {
    if (envVariables === null) {
      return <Spinner />;
    }
    if (envVariables.length === 0) {
      return "You have not created any environment variables yet.";
    }
    return envVariables.map((envVar, ix) => (
      <RowWithActions
        key={envVar.key}
        plusActions={{ Delete: () => deleteEnvVars(envVar.id) }}
      >
        <SimpleGrid columns={2} alignItems="center" spacing={4}>
          <Heading size="sm">{envVar.key}</Heading>
          <Text>#######</Text>
        </SimpleGrid>
      </RowWithActions>
    ));
  }, []);

  const Templates = useCallback(({ templates }) => {
    if (templates === null) {
      return <Spinner />;
    }
    if (templates.length === 0) {
      return "You have not created any template yet. Click publish on one of your flows to create a template.";
    }

    return (
      <VStack
        mb={8}
        spacing={4}
        align="stretch"
        divider={<StackDivider borderColor="gray.200" />}
      >
        {templates.map((template) => (
          <RowWithActions
            key={template.id}
            plusActions={{ Delete: () => deleteTemplate(template.id) }}
            actions={[
              {
                title: "Edit",
                to: `/explore/${template.id}`,
                variant: "outline",
                // colorScheme: "brand",
              },
            ]}
          >
            <VStack align="stretch">
              <Heading size="md">Template: {template.name}</Heading>
              <Text>Used {template.fork_count} times</Text>
            </VStack>
          </RowWithActions>
        ))}
      </VStack>
    );
  }, []);

  const {
    isOpen: isOpenCreateEnvVar,
    onOpen: onOpenCreateEnvVar,
    onClose: onCloseCreateEnvVar,
  } = useDisclosure();

  const {
    isOpen: isSubscribeOpen,
    onOpen: onSubscribeOpen,
    onClose: onSubscribeClose,
  } = useDisclosure();

  const {
    isOpen: isOpenCreateFlow,
    onOpen: onOpenCreateFlow,
    onClose: onCloseCreateFlow,
  } = useDisclosure();

  const {
    isOpen: isOpenInfo,
    onOpen: onOpenInfo,
    onClose: onCloseInfo,
  } = useDisclosure();

  const toast = useToast();
  const [info, setInfo] = React.useState<{ header: string; content: string }>({
    header: "",
    content: "",
  });
  const [flows, setFlows] = React.useState<FlowRead[] | null>(null);
  React.useEffect(() => {
    FLOWS_API.readMeApiV1FlowsMeGet()
      .then((r) => {
        setFlows(r.data);
      })
      .catch((e) => {
        redirectIfNotAuthorized(e);
      });
  }, []);

  const createFlow = ({ name, desc }: { name: string; desc?: string | null }) =>
    FLOWS_API.createFlowApiV1FlowsPost({ name, desc }).then((r) => {
      setFlows([...(flows || []), r.data]);
      onCloseCreateFlow();
      window.location.href = `/studio/${r.data.id}`;
    });

  const onDelete = (id: string) => {
    toastPromise(
      FLOWS_API.deleteFlowApiV1FlowsFlowIdDelete(id)
        .then(() => {
          setFlows((flows || []).filter((f) => f.id !== id));
        })
        .catch((e) => {
          console.error(e);
          throw e;
        }),
      toast
    );
  };

  const onCreateFlowClick = React.useCallback(() => {
    return canCreateNewFlow((flows || []).length).then((canCreate) => {
      if (canCreate) {
        onOpenCreateFlow();
      } else {
        onSubscribeOpen();
      }
    });
  }, [flows]);

  const onPublish = (id: string) => {
    toast({
      title: "Creating template...",
      status: "info",
    });
    return FLOWS_API.createTemplateFromFlowApiV1FlowsFlowIdTemplatePost(id)
      .then((r) => {
        toast.closeAll();
        toast({
          title: "Template created",
          status: "success",
        });
        navigate(`/explore/${r.data.id}`);
      })
      .catch((e) => {
        toast.closeAll();
        toast({
          title: "Failed to create template",
          description: getReadableApiError(e),
          status: "error",
        });
        throw e;
      });
  };

  return (
    <VStack align="stretch" p={4}>
      <SubscribeModal
        isOpen={isSubscribeOpen}
        onOpen={onSubscribeOpen}
        onClose={() => {
          onSubscribeClose();
        }}
        onSuccess={() => {
          onSubscribeClose();
        }}
      />
      <Modal isOpen={isOpenInfo} onClose={onCloseInfo} header={info.header}>
        {info.content}
      </Modal>
      <Card>
        <CardHeader>
          <Heading size="md">My Flows</Heading>
        </CardHeader>
        <CardBody>
          <Modal
            isOpen={isOpenCreateFlow}
            onClose={onCloseCreateFlow}
            header="Create Flow"
          >
            <JSONForm<FlowCreate>
              uiSchema={{
                "ui:options": { label: false },
                name: {
                  "ui:placeholder":
                    CREATE_FLOW_SCHEMA.properties.name.examples?.[0],
                },
                desc: {
                  "ui:widget": "textarea",
                  "ui:placeholder":
                    CREATE_FLOW_SCHEMA.properties.desc.examples?.[0],
                },
                flow_json: { "ui:widget": "hidden" },
              }}
              schema={CREATE_FLOW_SCHEMA}
              onSubmit={createFlow}
            />
          </Modal>
          <Flows
            flows={flows}
            onUpdate={(f: FlowRead) =>
              setFlows(
                (flows || []).map((flow) => (flow.id === f.id ? f : flow))
              )
            }
            onPublish={onPublish}
            onDelete={onDelete}
          />
          <Button
            variant="solid"
            leftIcon={<MdAdd />}
            colorScheme="brand"
            onClick={onCreateFlowClick}
          >
            Create Flow
          </Button>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <Heading size="md">My Templates</Heading>
        </CardHeader>
        <CardBody>
          <VStack
            mb={8}
            spacing={4}
            align="stretch"
            divider={<StackDivider borderColor="gray.200" />}
          >
            <Templates templates={templates} />
          </VStack>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <Heading size="md">Environment Variables</Heading>
        </CardHeader>
        <CardBody>
          <VStack
            mb={8}
            spacing={4}
            align="stretch"
            divider={<StackDivider borderColor="gray.200" />}
          >
            <EnvVars envVariables={envVariables} />
          </VStack>
          <Modal
            header="Add  API Key"
            isOpen={isOpenCreateEnvVar}
            onClose={onCloseCreateEnvVar}
          >
            <JSONForm<EnvVariableCreate>
              uiSchema={{
                "ui:options": { label: false },
              }}
              schema={CREATE_ENV_VAR_SCHEMA}
              onSubmit={(formData) => {
                return USERS_API.addEnvVarApiV1UsersMeEnvVarsPost(
                  formData
                ).then((r) => {
                  onCloseCreateEnvVar();
                  setEnvVariables((keys) => {
                    if (keys === null) {
                      return [r.data];
                    }
                    return [...keys, r.data];
                  });
                });
              }}
            />
          </Modal>
          <Button
            onClick={onOpenCreateEnvVar}
            variant="outline"
            colorScheme="brand"
          >
            Add Environment Variable
          </Button>
        </CardBody>
      </Card>
    </VStack>
  );
};

export default Home;
