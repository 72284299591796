import {
  Box,
  useDisclosure,
  VStack,
  Tooltip,
  Collapse,
  Heading,
  Flex,
  HStack,
  Badge,
  IconButton,
  Text,
  Button,
  StackDivider,
} from "@chakra-ui/react";
import { Handle, Position, useConnection } from "@xyflow/react";
import classnames from "classnames";
import { BsArrowsCollapse } from "react-icons/bs";
import { FiHelpCircle } from "react-icons/fi";
import {
  MdEdit,
  MdDelete,
  MdAdd,
  MdExpand,
  MdLight,
  MdLightbulb,
} from "react-icons/md";
import JSONForm from "src/JSONForm";
import { getUserFriendlyName } from "src/nodesFriendly";
import NodeSummary, {
  getFriendlyTitle,
  getFriendlyType,
} from "src/studio/nodeSummary";
import { FlowsApi, NodeType } from "typescript-axios";
import Modal from "./Modal";
import React from "react";
import exp from "constants";
import NodeHoveredContext from "src/node-hovered-context";

const HANDLE_STYLES = {
  target: {
    right: -10,
    // top: 15,
    background: "#d9f7fa",
    minWidth: 20,
    height: 20,
    border: "1px solid #76c7d1",
    borderRadius: 4,
    placeItems: "center",
    display: "grid",
    color: "#333",
    zIndex: 2,
  },
  source: {
    right: -10,
    // top: 43,
    background: "#ffebe6",
    minWidth: 20,
    height: 20,
    border: "1px solid #e0a800",
    borderRadius: 4,
    placeItems: "center",
    display: "grid",
    color: "#333",
    zIndex: 2,
  },
  run: {
    right: -10,
    // top: 43,
    background: "#f2f2f2",
    minWidth: 20,
    height: 20,
    border: "1px dotted #000",
    borderRadius: "100%",
    placeItems: "center",
    display: "grid",
    color: "#333",
    zIndex: 2,
  },
};

const createHandles = (schema, type, isRunConnected: boolean = false) => {
  const totalHandles = Object.keys(schema.properties || {})?.length ?? 0;
  const pct = Math.round(100 / (totalHandles + 1));
  const { required, properties } = schema;

  return Object.entries(properties || {}).map(([key, value], ix) => {
    const isRun = key === "run";

    return (
      <Tooltip
        key={key}
        label={
          <Box>
            <Text>{getFriendlyTitle(value)}</Text>
            <Text fontSize="xs">({getFriendlyType(value)})</Text>
          </Box>
        }
      >
        <Handle
          type={type}
          position={type === "target" ? Position.Top : Position.Bottom}
          style={{
            ...HANDLE_STYLES[isRun ? "run" : type],
            left: `${pct * (1 + ix)}%`,
          }}
          className={classnames({
            "run-connected": isRun && isRunConnected,
          })}
          id={key}
        >
          <Box pointerEvents="none" position="relative">
            {
              <Text fontSize="xs">
                {type === "target" ? "i" : "o"}
                {1 + ix}
              </Text>
            }
            {type === "target" && required?.includes(key) && (
              <Text
                position="absolute"
                top={-1}
                right={-1}
                color="red"
                fontWeight="bold"
                pointerEvents="none"
              >
                *
              </Text>
            )}
          </Box>
        </Handle>
      </Tooltip>
    );
  });
};

export const TypeDesc = ({ desc }): { desc: string | null } => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  return (
    <VStack align="stretch">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="sm" justifyContent="center">
          <HStack>
            <Text>What this block does 💡</Text>
          </HStack>
        </Heading>
        <IconButton
          aria-label="Toggle Block Type Description"
          size="xs"
          icon={<MdExpand />}
          onClick={onToggle}
        />
      </Flex>
      <Box as={Collapse} in={isOpen} minW="100%">
        <Text w="full">
          {desc?.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Text>
      </Box>
    </VStack>
  );
};

interface NodeToolTipInterface {
  nodeType: NodeType;
  children: React.ReactNode;
}

const NodeToolTip = ({ nodeType, children }: NodeToolTipInterface) =>
  // <Tooltip
  //   label={
  //     <Box>
  //       {nodeType.desc.split("\n").map((p) => (
  //         <Text key={p}>{p}</Text>
  //       ))}
  //     </Box>
  //   }
  // >
  children;
// </Tooltip>

interface NodeProps {
  nodeType: NodeType;
  inputSchema: any;
  outputSchema: any;
}

const Node = ({
  nodeId,
  nodeType,
  inputSchema,
  outputSchema,
  initSchema,
  editNode,
  deleteNode,
  data,
  isDemo,
}): NodeProps => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const connection = useConnection();
  const { nodeHovered } = React.useContext(NodeHoveredContext);

  let {
    isOpen: isExpanded,
    onOpen: onExpand,
    onClose: onCollapse,
  } = useDisclosure({
    defaultIsOpen: !nodeType.kls.toLowerCase().includes("format"),
  });

  const isTarget =
    connection.inProgress &&
    connection.fromNode.id !== nodeId &&
    nodeHovered === nodeId;
  isExpanded = isTarget || isExpanded;

  const {
    isOpen: isOpenHelp,
    onOpen: onOpenHelp,
    onClose: onCloseHelp,
  } = useDisclosure();
  const { isRunConnected, name, ...rest } = data;

  const userFriendlyName = React.useMemo(
    () => getUserFriendlyName(nodeType.kls),
    [nodeType.kls]
  );
  const className = React.useMemo(
    () =>
      classnames({
        "ai-box": ["Instruct", "Indexer"].includes(nodeType.kls),
        "pulsating-box": ["Schedule", "NewEmail", "InstagramNewDm"].includes(
          nodeType.kls
        ),
        "default-box":
          !["Instruct", "NewEmail"].includes(nodeType.kls) && isExpanded,
      }),
    [nodeType.kls, isExpanded]
  );

  const tgtHandles = React.useMemo(() => {
    if (isExpanded) {
      return createHandles(inputSchema, "target", isRunConnected);
    } else {
      return "";
    }
  }, [inputSchema, isRunConnected, isExpanded]);

  const srcHandles = React.useMemo(() => {
    if (isExpanded) {
      return createHandles(outputSchema, "source");
    } else {
      return "";
    }
  }, [outputSchema, isExpanded]);

  const isConstant = React.useMemo(
    () =>
      ["text", "number", "integer", "boolean"].includes(
        nodeType.kls.toLowerCase()
      ),
    [nodeType.kls]
  );

  const centerTitle = React.useCallback(() => {
    const color = ""; //isConstant ? "gray.700" : "black";

    let displayText = name;
    if (rest.template) {
      displayText = rest.template;
    } else if (rest.value) {
      displayText = rest.value;
    }

    return (
      <Box maxW="15rem">
        <Text
          fontSize="xs"
          color={color}
          whiteSpace={nodeType.kls === "Text" ? "nowrap" : "none"}
          overflow={nodeType.kls === "Text" ? "hidden" : "visible"}
          textOverflow={nodeType.kls === "Text" ? "ellipsis" : "none"}
        >
          {displayText}
        </Text>
      </Box>
    );
  }, [rest.value, rest.template, name]);

  if (!isExpanded) {
    const inputProps = Object.entries(inputSchema.properties || {});
    const outputProps = Object.entries(outputSchema.properties || {});
    return (
      <NodeToolTip nodeType={nodeType}>
        <Box className={className} borderRadius={4}>
          {inputProps.map(([key]) => {
            return <Handle type="target" position={Position.Top} id={key} />;
          })}
          <IconButton
            isDisabled={isDemo}
            aria-label="Expand Block"
            size="xs"
            icon={<MdExpand />}
            onClick={onExpand}
            border="1px"
          />
          {outputProps.map(([key]) => {
            return <Handle type="source" position={Position.Bottom} id={key} />;
          })}
        </Box>
      </NodeToolTip>
    );
  }

  return (
    <Box
      position="relative"
      className={className}
      minW={150}
      borderRadius={isConstant ? 0 : 4}
      p={4}
      textAlign="center"
      bg="white"
    >
      {tgtHandles}
      <Box>
        <IconButton
          aria-label="Collapse Block"
          size="xs"
          visibility={isDemo ? "hidden" : "visible"}
          icon={<BsArrowsCollapse />}
          onClick={onCollapse}
          border="1px"
          position="absolute"
          right="0"
          top="50%"
          transform="translate(50%, -50%)"
        />
        <VStack align="start" spacing={1}>
          <NodeToolTip nodeType={nodeType}>
            <Flex w="full" justifyContent="space-between">
              <HStack>
                <Badge
                  fontWeight="800"
                  color="gray.700"
                  fontSize="xs"
                  colorScheme={
                    {
                      Indexer: "blue",
                      Instruct: "blue",
                      NewEmail: "green",
                      SendEmailSMTP: "teal",
                      Database: "orange",
                      SheetsRead: "orange",
                      SheetsWrite: "teal",
                    }[nodeType.kls] || "gray"
                  }
                >
                  {userFriendlyName}
                </Badge>
                <Text fontSize="sm" fontWeight="bold">
                  {nodeType.is_trigger && " Trigger"}
                  {isConstant && " Constant"}
                </Text>
              </HStack>
              {!isDemo && (
                <>
                  <IconButton
                    colorScheme="brand"
                    variant="ghost"
                    borderRadius={0}
                    icon={<MdEdit />}
                    size="xs"
                    onClick={onOpen}
                    aria-label="Edit Block"
                  />
                  <Modal
                    size="xl"
                    header={
                      <HStack spacing={4} alignItems="center">
                        <Text>Edit Block</Text>
                        <Button
                          size="xs"
                          leftIcon={<MdDelete />}
                          color="red"
                          onClick={() => deleteNode().then(onClose)}
                          variant="outline"
                        >
                          Delete Block
                        </Button>
                      </HStack>
                    }
                    isOpen={isOpen}
                    onClose={onClose}
                  >
                    <VStack align="stretch">
                      <Box border="1px" borderRadius={4} p={2}>
                        <TypeDesc desc={nodeType.desc} />
                      </Box>
                      <JSONForm
                        toastOptions={{
                          success: {
                            title: "Node updated",
                            description:
                              "If your flow was running you need to restart it",
                          },
                          error: {
                            title: "Error updating node",
                          },
                        }}
                        schema={initSchema}
                        initialData={{ name, ...rest }}
                        onSubmit={(formData) =>
                          editNode(formData).then(onClose)
                        }
                        uiSchema={{
                          "ui:options": { label: false },
                          pos_x: { "ui:widget": "hidden" },
                          pos_y: { "ui:widget": "hidden" },
                        }}
                      />
                    </VStack>
                  </Modal>
                </>
              )}
            </Flex>
          </NodeToolTip>
          <HStack alignItems="center" spacing={0}>
            {centerTitle()}
            {!isDemo && (
              <IconButton
                aria-label={`Help for ${userFriendlyName}`}
                icon={<FiHelpCircle />}
                onClick={onOpenHelp}
                size="xs"
                variant="link"
              />
            )}
          </HStack>
        </VStack>
        <Modal size="xl" isOpen={isOpenHelp} onClose={onCloseHelp}>
          <NodeSummary nodeType={nodeType} outputSchema={outputSchema} />
        </Modal>
      </Box>
      {srcHandles}
    </Box>
  );
};

export default Node;
