import { useSearchParams } from "react-router-dom";
import React from "react";
import FacebookSignin from "./components/auth/meta-signin";

const AuthMeta = () => {
  const APP_ID = "1175275613703378";
  const API_VERSION = "v20.0";

  const [searchParams, setSearchParams] = useSearchParams();
  const [configId, setConfigId] = React.useState<string | null>(null);
  const [redirectUrl, setRedirectUrl] = React.useState<string | null>(null);
  const [isSdkLoaded, setIsSdkLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isSdkLoaded) return;

    const configId = searchParams.get("config_id");
    const redirectUrl = searchParams.get("redirect_url");
    console.debug("configId", configId);
    if (configId && redirectUrl) {
      setConfigId(configId);
      setRedirectUrl(redirectUrl);
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, isSdkLoaded]);

  React.useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: APP_ID,
        xfbml: true,
        version: API_VERSION,
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    const checkSdkLoaded = setInterval(() => {
      if (window.FB) {
        clearInterval(checkSdkLoaded);
        // not super clean, but want to start fresh every time
        if (searchParams.get("progress")) {
          setIsSdkLoaded(true);
        } else {
          window.FB.getLoginStatus(function () {
            setIsSdkLoaded(true);
          });
        }
      }
    }, 500);

    return () => clearInterval(checkSdkLoaded);
  }, [APP_ID, API_VERSION]);

  return (
    <>
      <div id="fb-root"></div>
      <FacebookSignin
        configId={configId}
        appId={APP_ID}
        apiVersion={API_VERSION}
      />
    </>
  );

};

export default AuthMeta;
