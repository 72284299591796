const MAP = {
  SendEmailSMTP: "Send Email (SMTP)",
  EmailSourceIMAP: "Email Source (IMAP)",
  NewEmail: "New Email (IMAP)",
  Instruct: "ChatGPT",
  FormatText: "Text Formatter",
  FormatTable: "Table Formatter",
  SheetsRead: "Read Google Sheets",
  SheetsWrite: "Write Google Sheets",
  Indexer: "Smart Search",
  Boolean: "True/False",
  Switch: "Condition",
  ToTable: "To Table",
  CsvToTable: "CSV to Table",
  DateTimeManipulate: "Manipulate Datetime",
  ScrapePageDynamic: "Scrape Page (Dynamic)",
  ScrapePageStatic: "Scrape Page (Static)",
  InstagramNewDm: "New Instagram DM",
  InstagramSendDm: "Send Instagram DM",
  ScrapePagesDynamic: "Scrape Multiple Pages (Dynamic)",
};

const getUserFriendlyName = (kls: string): string => MAP[kls] ?? kls;

export { getUserFriendlyName };
