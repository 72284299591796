import {
  VStack,
  Button,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import Modal from "./Modal";

const OAuthModalInfo = ({ oauthUrl, onClose }) => {
  return (
    <Modal
      isOpen={oauthUrl !== null}
      onClose={onClose}
      header="Authentication Required"
    >
      <VStack align="stretch">
        <Text>
          Some blocks need you to give special permissions. Please click the
          button below to start the authentication process.
        </Text>
        <Alert status="info" variant="left-accent">
          <AlertIcon />
          <AlertDescription fontWeight="bold">
            Make sure popups are enabled on this page.
          </AlertDescription>
        </Alert>
        <Button
          colorScheme="brand"
          onClick={() => {
            onClose();
            if (oauthUrl !== null) {
              window.open(oauthUrl, "_blank");
            }
          }}
        >
          Authenticate
        </Button>
      </VStack>
    </Modal>
  );
};
export default OAuthModalInfo;
