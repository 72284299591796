import { TemplateRead, TemplatesApi } from "typescript-axios";
import axios, { getAxiosParams } from "./lib/axios.config";
import React from "react";
import {
  Badge,
  Box,
  Card,
  CardBody,
  Button,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
  Spinner,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { createCustomNodes, getEdgesAndNodesFromJson } from "./appUtils";
import {
  Background,
  Controls,
  ReactFlow,
  ReactFlowProvider,
} from "@xyflow/react";
import { readNodeTypes } from "./flowActions";
import { MdAdd, MdBuild, MdRocket } from "react-icons/md";

interface TemplateAndFlowData {
  template: TemplateRead;
  edges: any[];
  nodes: any[];
}

const TEMPLATES_API = new TemplatesApi(getAxiosParams(), undefined, axios);
const Explore = () => {
  const [templates, setTemplates] = React.useState<
    TemplateAndFlowData[] | null
  >(null);
  const [customNodes, setCustomNodes] = React.useState<any>({});

  React.useEffect(() => {
    readNodeTypes().then((schema) => {
      setCustomNodes(createCustomNodes(schema, null, null, null, true));
    });
  }, []);

  React.useEffect(() => {
    TEMPLATES_API.readTemplatesApiV1TemplatesGet().then((res) => {
      const templatesAndFlowData = [];
      for (const template of res.data) {
        const [edges, nodes] = getEdgesAndNodesFromJson(template.template_json);
        templatesAndFlowData.push({ template, edges, nodes });
      }
      setTemplates(templatesAndFlowData);
    });
  }, []);

  return (
    <VStack p={{ base: 4, md: 12 }} align="stretch">
      <Box>
        <Heading>Pre-built Flows</Heading>
        <Text>
          Jump start your AI flow with these templates. Click on a template to
          get started in minutes!
        </Text>
      </Box>
      {templates === null ? (
        <Spinner />
      ) : (
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 4, md: 12 }}
          mt={{ base: 4 }}
        >
          {templates.map(({ template, edges, nodes }) => {
            return (
              <GridItem>
                <Card border="2px solid" borderColor="gray.300">
                  <CardBody bg="gray.50">
                    <Flex justifyContent="space-between">
                      <Heading size="md">{template.name}</Heading>
                      <Box>
                        {template.is_featured && (
                          <Badge colorScheme="brand">featured</Badge>
                        )}
                      </Box>
                    </Flex>
                    {template.desc}
                  </CardBody>
                  <Box h="300px" boxShadow={"xl"} position="relative">
                    <ReactFlowProvider>
                      <ReactFlow
                        fitView
                        edges={edges}
                        nodes={nodes}
                        onNodesChange={() => null}
                        onEdgesChange={() => null}
                        onConnect={() => null}
                        edgesFocusable={false}
                        nodesDraggable={false}
                        nodesConnectable={false}
                        nodesFocusable={true}
                        elementsSelectable={true}
                        zoomOnScroll={false}
                        panOnScroll={false}
                        preventScrolling={false}
                        nodeTypes={customNodes || {}}
                      >
                        <Controls />
                        <Background />
                      </ReactFlow>
                    </ReactFlowProvider>
                    <Button
                      leftIcon={<MdRocket />}
                      m="2"
                      colorScheme="brand"
                      position="absolute"
                      bottom={0}
                      right={0}
                      as={Link}
                      href={`/explore/${template.id}`}
                    >
                      Start Building
                    </Button>
                  </Box>
                </Card>
              </GridItem>
            );
          })}
        </SimpleGrid>
      )}
    </VStack>
  );
};

export default Explore;
